import { isFinite } from 'lodash'

export const delayedVariable = (value, target, mult, accuracy) => {
	if(!isFinite(accuracy)){ accuracy = 100 }

	let newValue = value - (mult * (value - target))
	newValue = Math.round(newValue * accuracy) / accuracy

	const difference = Math.abs(target - newValue)
	const errorMargin = 1 / (accuracy / 10)
	
	if(difference < errorMargin){
		newValue = target
	}

	return newValue
}

export const pathToPolygonStr = (path) => {
	let str = ''
	for(let i = 0; i < path.length; i++){
		if(str.length > 0){
			str += ', '
		}
		str += `${ path[i][0] }px ${ path[i][1] }px`
	}
	return str
}

export const clipPathFromPath = (path) => {
	const str = pathToPolygonStr(path)
	return `polygon(${ str })`
}

export const pathFromRect = (rectFrom, rectTo) => {
	return [
		[rectFrom[0], rectFrom[1]],
		[rectTo[0], rectFrom[1]],
		[rectTo[0], rectTo[1]],
		[rectFrom[0], rectTo[1]]
	]
}

export const roundTo = (num, round, type = null) => {
	switch(type){
		case 'floor':
			return Math.floor((num)*round)/round
		case 'ceil':
			return Math.ceil((num)*round)/round
		default:
			return Math.round((num)*round)/round
	}
}

export const ratioFromImage = (image) => {
	if(!image){ return null }
	return isFinite(image.aspectRatio) ? (1 / image.aspectRatio) : image.height / image.width
}

export const imageIsPortrait = (image) => {
	return ratioFromImage(image) > 1
}

export const fitDimensionsToBox = (box, dimensions, contain = false) => {
	let width = box.width
	let height = isFinite(dimensions.ratio) ? box.width * dimensions.ratio : dimensions.height / dimensions.width * box.width

	if(
		isFinite(box.height)
		&& (
			(!contain && height < box.height)
			|| (contain && height > box.height)
		)
	){
		height = box.height
		width = isFinite(dimensions.ratio) ? box.height / dimensions.ratio : dimensions.width / dimensions.height * box.height
	}

	return {
		width: roundTo(width, 100),
		height: roundTo(height, 100)
	}
}

export const shouldShowPortrait = (box) => {
	return box.width <= box.height * 0.9
}