import React 			    from 'react'
import classnames           from 'classnames'
import {
    get
} from 'lodash'

import {
    ImageGallery
} from '../../image-gallery'

import TabOptions           from '../tab-options'

import {
    formatTitle,
    formatSvgAndImage
} from '../../../lib/_helpers'

class Elevations extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0
        }
    }

    render() {
        const {
            activeIndex
        } = this.state
        const {
            house,
            title,
            subtitle
        } = this.props

        const elevations = get(house, 'acf.elevation_images')

        return <React.Fragment>
            <div className="housesOverlay-row housesOverlay-tabContent-title">
                <div className="textBlock textBlock--center">
                    { title ? <h3>{ formatTitle(title) }</h3> : null }
                    { subtitle ? <p>{ subtitle }</p> : null }
                </div>
            </div>
            <div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-tabContent-content">
                { elevations ? <div className="housesOverlay-rows">
                        <div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-tabContent-gallery">
                            <div className="housesOverlay-tabContent-gallery-wrapper">
                                <ImageGallery
                                    activeIndex={ activeIndex }
                                    contain={ true }
                                    toggleClassName="toggleWrapper--fadeInOut"
                                    swapSvg={ true }
                                    images={ elevations.map((_elevation, _i) => {
                                        return formatSvgAndImage(_elevation)
                                    }) } />
                            </div>
                        </div>
                        <div className="housesOverlay-row housesOverlay-bar">
                            <TabOptions
                                className="housesOverlay-bar-set--center"
                                activeIndex={ activeIndex }
                                tabs={ elevations.map((_elevation, _i) => {
                                    return _elevation.label
                                }) }
                                onTabSelect={ (index) => {
                                    this.setState({
                                        activeIndex: index
                                    })
                                } } />
                        </div>
                    </div> : null }
            </div>
        </React.Fragment>
    }
}

export default Elevations