import React     from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

export default (props) => {
	const { url } = props

	if(!url || typeof window === 'undefined'){
		return null
	}

	return <div className="typeformForm">
		<ReactTypeformEmbed
			url={ url }
			hideHeaders={ true }
			hideFooter={ true }
			opacity={ 0 } />
	</div>
}