import React                from 'react'
import classnames           from 'classnames'
import { connect }          from 'react-redux'

class Cursor extends React.Component {
	render() {
		const {
			cursor
		} = this.props

		if(!cursor){ return null }

		const translate = `translate3d(${cursor.position.x}px,${cursor.position.y}px,0)`

		return <div
			className={ classnames('pseudoCursor', 'pseudoCursor--' + cursor.icon) }
			style={{
				WebkitTransform: translate,
				Transform: translate
			}}
			/>
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

	return {
		cursor: ui.cursor
	}
}

export default connect(mapStateToProps, null)(Cursor)