import React 			    from 'react'
import classnames           from 'classnames'
import {
    get
} from 'lodash'

import {
    ImageGallery
} from '../../image-gallery'

import {
    renderHTML
} from '../../../lib/_helpers'

class Specifications extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            house,
            title,
            subtitle
        } = this.props

        const specifications = get(house, 'acf.specifications')

        return <React.Fragment>
            <div className="housesOverlay-row housesOverlay-tabContent-title">
                <div className="textBlock textBlock--center">
                    { title ? <h3>{ title }.</h3> : null }
                    { subtitle ? <h5>{ subtitle }</h5> : null }
                </div>
            </div>
            <div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-tabContent-content">
                <div className="cont">
                    <div className="housesOverlay-specifications">
                        <div className="housesOverlay-specification">
                            { specifications.dimensions ? <div className="textBlock textBlock--light">
                                <h4 className="h3--sans">Dimensions</h4>
                                { specifications.dimensions.map((_dimension, _i) => {
                                    return <p
                                        key={ _i }>
                                        { _dimension.label ? <strong>{ _dimension.label }</strong> : null }
                                        { _dimension.label && _dimension.detail ? <br /> : null }
                                        { _dimension.detail ? renderHTML(_dimension.detail) : null }
                                    </p>
                                })}
                            </div> : null }
                        </div>
                        <div className="housesOverlay-specification">
                            { specifications.exteriors ? <div className="textBlock textBlock--light">
                                <h4 className="h3--sans">Exteriors</h4>
                                { specifications.exteriors ? renderHTML(specifications.exteriors) : null }
                            </div> : null }
                        </div>
                        <div className="housesOverlay-specification housesOverlay-specification--double">
                            { specifications.interiors ? <div className="textBlock textBlock--light">
                                <h4 className="h3--sans">Interiors</h4>
                                { specifications.interiors ? renderHTML(specifications.interiors) : null }
                            </div> : null }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

export default Specifications