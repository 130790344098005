import { 
	applyMiddleware,
	createStore
} from 'redux'
import logger 			from 'redux-logger'
import thunk 			from 'redux-thunk'

import appState  		from './reducers'

let reduxMiddleware = [thunk]
if(process.env.NODE_ENV !== 'production'){
	reduxMiddleware.push(logger)
}

export const configureStore = () => {
	const store = createStore(
		appState,
		applyMiddleware(...reduxMiddleware)
	)

	if(module.hot){
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers/index')
			store.replaceReducer(nextRootReducer)
		})
	}

	return store
}