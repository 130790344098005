import React 		from 'react'

const defaultWindowDimensions = {
	left: 0,
	top: 0,
	width: 0,
	height: 0,
	scrollPos: 0
}

export default React.createContext(defaultWindowDimensions)