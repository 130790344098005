import zenscroll from 'zenscroll'

export const scrollToID = (id, time = 900, offset = 0) => {
	const el = document.getElementById(id)
	if(el){
		zenscroll.setup(null, offset)
		zenscroll.to(el, time)
	}
}

export const scrollToY = (y, time = 900) => {
	zenscroll.setup(null, 0)
	zenscroll.toY(y, time)
}