import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PS from 'publish-subscribe-js'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { StaticQuery, graphql } from 'gatsby'
import {
  get,
  find,
  findIndex,
  filter,
  isFinite,
  indexOf,
  orderBy,
} from 'lodash'

import Breadcrumbs from '../breadcrumbs'
import CloseButton from '../close-button'
import { ImageGallery } from '../image-gallery'
import SidePanel from '../side-panel'

import * as Tabs from './tabs'
import Panel from './panel'
import TabOptions from './tab-options'
import MatrixText from './matrix-text'
import Dropdown from './dropdown'

import breakpoints from '../../lib/constants/breakpoints.json'
import { bgColourIsDark, renderHTML } from '../../lib/_helpers'
import {
  InlineImageFragment,
  FileFragment,
  FullScreenImageFragment,
  HouseElevationImageFragment,
} from '../../lib/fragments'

import { openOverlay, closeOverlay } from '../../redux/actions'

import {
  housesFromData,
  bedroomsFromData,
  hasHouseWithBedrooms,
  housesForActiveBedroom,
  bedroomsForActiveHouse,
  bedroomsAndOptions,
  houseTabs,
  houseTitle,
  labelForTab,
  componentKeyForTab,
  optionLabelForIndex,
  getDetailMatrixHousesID,
  getDetailMatrixOptionsID,
} from './_helpers'

class HousesOverlay extends React.Component {
  constructor(props) {
    super(props)

    const { initialBedroomChoice, initialHouseChoice, bedrooms, houses } = props

    const initialActiveHouseIndex = initialHouseChoice
      ? findIndex(houses, { wordpress_id: initialHouseChoice })
      : null
    const hasInitialHouseChoice =
      isFinite(initialActiveHouseIndex) && initialActiveHouseIndex >= 0

    let _initialBedroomChoice = null
    if (hasInitialHouseChoice) {
      const initialBedroomsFromHouse = bedroomsForActiveHouse(
        houses[initialActiveHouseIndex].bedrooms,
        bedrooms
      )
      if (initialBedroomsFromHouse && initialBedroomsFromHouse.length > 0) {
        _initialBedroomChoice = initialBedroomsFromHouse[0].wordpress_id
      }
    }
    if (!_initialBedroomChoice) {
      _initialBedroomChoice = initialBedroomChoice
    }

    const initialActiveBedroomIndex = _initialBedroomChoice
      ? findIndex(bedrooms, { wordpress_id: _initialBedroomChoice })
      : null
    const hasInitialBedroomChoice =
      isFinite(initialActiveBedroomIndex) && initialActiveBedroomIndex >= 0

    let initialOptionIndex = 0
    if (hasInitialHouseChoice && hasInitialBedroomChoice) {
      const initialHouses = housesForActiveBedroom(
        bedrooms[initialActiveBedroomIndex].wordpress_id,
        houses
      )
      initialOptionIndex = findIndex(initialHouses, {
        wordpress_id: initialHouseChoice,
      })
    }

    this.state = {
      activeBedroomIndex: hasInitialBedroomChoice
        ? initialActiveBedroomIndex
        : 0,
      activeOptionIndex:
        isFinite(initialOptionIndex) && initialOptionIndex >= 0
          ? initialOptionIndex
          : 0,
      activeTab: houseTabs[0],
      priceActive: false,
      overviewActive: false,
    }
  }

  renderTab(activeHouse, _houseTitle, _houseSubtitle, props) {
    const { activeTab } = this.state

    const componentKey = componentKeyForTab(activeTab)
    const Component =
      componentKey && Tabs[componentKey] ? Tabs[componentKey] : null

    return Component ? (
      <div
        className={classnames(
          'housesOverlay-tabContent housesOverlay-rows',
          `housesOverlay-tabContent--${activeTab}`
        )}
      >
        <Component
          house={activeHouse}
          title={_houseTitle}
          subtitle={_houseSubtitle}
          {...props}
        />
      </div>
    ) : null
  }

  renderHouseOptions(activeHouses, activeHouse, isTablet, isMobile) {
    const { activeBedroomIndex, activeOptionIndex } = this.state
    const { bedrooms, houses } = this.props

    if (isMobile) {
      const bedroomsList = bedroomsAndOptions(bedrooms, houses)
      const activeBedroomListIndex = findIndex(bedroomsList, {
        bedroomIndex: activeBedroomIndex,
        optionIndex: activeOptionIndex,
      })

      return bedroomsList && bedroomsList.length > 0 ? (
        <Dropdown
          label="Bedrooms"
          active={activeBedroomListIndex}
          options={bedroomsList.map((_option, _i) => {
            return _option.label
          })}
          onChange={index => {
            const _bedroomListItem = bedroomsList[index]

            this.setState({
              activeBedroomIndex: _bedroomListItem.bedroomIndex,
              activeOptionIndex: _bedroomListItem.optionIndex,
            })
          }}
        />
      ) : null
    } else {
      return (
        <React.Fragment>
          {bedrooms && bedrooms.length > 0 ? (
            <div className="housesOverlay-bar-set">
              <div className="housesOverlay-bar-set-label">Bedrooms</div>
              <Breadcrumbs
                activeIndex={activeBedroomIndex}
                length={bedrooms.length}
                crumbContent={bedrooms.map((_bedroom, _i) => {
                  return _bedroom.name
                })}
                onCrumbSelect={index => {
                  this.setState({
                    activeBedroomIndex: index,
                    activeOptionIndex: 0,
                  })
                }}
                dark={true}
              />
            </div>
          ) : null}
          {activeHouses.length > 1 ? (
            <div className="housesOverlay-bar-set">
              <div className="housesOverlay-bar-set-options">
                {activeHouses.map((_house, _i) => {
                  return (
                    <div
                      key={_i}
                      className={classnames('housesOverlay-bar-set-option', {
                        'housesOverlay-bar-set-option--active':
                          _i === activeOptionIndex,
                      })}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()

                        this.setState({
                          activeOptionIndex: _i,
                        })
                      }}
                    >
                      {optionLabelForIndex(_i, isTablet)}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
        </React.Fragment>
      )
    }
  }

  renderTabOptions(activeHouse, isMobile) {
    const { activeTab, priceActive } = this.state

    const activeTabIndex = indexOf(houseTabs, activeTab)
    const tabs = [
      ...houseTabs.map((_tab, _i) => {
        return labelForTab(_tab)
      }),
    ]
    const onTabSelect = index => {
      const _tab = houseTabs[index]

      if (_tab === 'prices') {
        this.setState({
          priceActive: true,
        })
      } else if (_tab === 'overview') {
        this.setState({
          overviewActive: true,
        })
      } else if (index < houseTabs.length) {
        this.setState({
          activeTab: houseTabs[index],
        })
      }
    }

    if (isMobile) {
      return (
        <Dropdown
          label="Browse"
          active={activeTabIndex}
          options={tabs}
          onChange={onTabSelect}
        />
      )
    } else {
      return (
        <TabOptions
          activeIndex={activeTabIndex}
          onTabSelect={onTabSelect}
          tabs={tabs}
        />
      )
    }
  }

  render() {
    const {
      activeBedroomIndex,
      activeOptionIndex,
      activeTab,
      priceActive,
      overviewActive,
    } = this.state
    const {
      data,
      houses,
      houseOptions,
      bedrooms,
      detailMatrixHousesID,
      detailMatrixOptionsID,
      screenSize,
      dispatch,
    } = this.props

    const activeBedroom = bedrooms[activeBedroomIndex]

    const activeHouses = housesForActiveBedroom(
      activeBedroom.wordpress_id,
      houses
    )
    const activeHouse = activeHouses[activeOptionIndex]

    const activeOverview = get(activeHouse, 'acf.overview')
    const activePriceAmounts = get(activeHouse, 'acf.price_amounts')
    const priceDescription = get(houseOptions, 'prices_description')

    const _houseTitle = houseTitle(activeHouse, bedrooms)
    const _houseSubtitle =
      activeHouses.length > 1 ? optionLabelForIndex(activeOptionIndex) : null

    const activeTabContent = this.renderTab(
      activeHouse,
      _houseTitle,
      _houseSubtitle,
      {
        houseOptions,
        detailMatrixHousesID,
        detailMatrixOptionsID,
      }
    )

    const isTablet = screenSize.width <= breakpoints.tablet
    const isMobile = screenSize.width <= breakpoints.tabletPortrait

    return (
      <div
        className={classnames('housesOverlay', 'housesOverlay-rows', 'overlay')}
        style={{
          width: screenSize.width,
          height: screenSize.height,
        }}
      >
        <div className="housesOverlay-row housesOverlay-bar housesOverlay-bar--top">
          <div className="nav-margin housesOverlay-bar-top-logo">
            <div className="nav-logo-logo"></div>
          </div>
          <div
            className="nav-margin housesOverlay-bar-top-close"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              dispatch(closeOverlay())
            }}
          >
            <CloseButton />
          </div>
        </div>
        <div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-content">
          <TransitionGroup>
            {activeTabContent ? (
              <CSSTransition
                key={`${activeBedroomIndex}_${activeOptionIndex}_${activeTab}`}
                classNames="housesOverlay-tabContent-fade-"
                timeout={{
                  enter: 1300,
                  exit: 600,
                }}
              >
                {activeTabContent}
              </CSSTransition>
            ) : null}
          </TransitionGroup>
        </div>
        <div className="housesOverlay-row housesOverlay-row--bar housesOverlay-bar housesOverlay-bar--footer">
          {this.renderHouseOptions(
            activeHouses,
            activeHouse,
            isTablet,
            isMobile
          )}
          {!isMobile ? (
            <div className="housesOverlay-bar-set housesOverlay-bar-set--space" />
          ) : null}
          {this.renderTabOptions(activeHouse, isMobile)}
        </div>
        {activeOverview ? (
          <Panel
            bool={overviewActive}
            onClose={() => {
              this.setState({
                overviewActive: false,
              })
            }}
            panelClassName="housesOverlay-price"
            panelBgClassName="housesOverlay-bg--price"
            title={`${_houseTitle}.`}
            subtitle={_houseSubtitle}
            textContent={
              <React.Fragment>
                {activeOverview ? renderHTML(activeOverview) : null}
                <MatrixText
                  detailMatrixHousesID={detailMatrixHousesID}
                  detailMatrixOptionsID={detailMatrixOptionsID}
                />
              </React.Fragment>
            }
          />
        ) : null}
        <Panel
          bool={priceActive}
          onClose={() => {
            this.setState({
              priceActive: false,
            })
          }}
          panelClassName="housesOverlay-price"
          panelBgClassName="housesOverlay-bg--price"
          s
          title={`${_houseTitle}`}
          subtitle={_houseSubtitle}
          textContent={
            <React.Fragment>
              {priceDescription ? renderHTML(priceDescription) : null}
              {activePriceAmounts ? (
                <div className="houseOverlay-priceList">
                  {activePriceAmounts &&
                  activePriceAmounts.length > 0
                    ? activePriceAmounts.map((_priceAmount, _i) => {
                        return (
                          <div
                            key={_i}
                            className="houseOverlay-priceList-amount"
                          >
                            <div className="houseOverlay-priceList-amount-label">
                              {_priceAmount.item}
                            </div>
                            <div className="houseOverlay-priceList-amount-price">
                              {_priceAmount.amount}
                            </div>
                          </div>
                        )
                      })
                    : null}
                  {/*activePrice.total ? (
                    <div className="houseOverlay-priceList-amount">
                      <div className="houseOverlay-priceList-amount-label">
                        Total
                      </div>
                      <div className="houseOverlay-priceList-amount-price">
                        {activePrice.total}
                      </div>
                    </div>
                  ) : null*/}
                </div>
              ) : null}
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

const ReduxHousesOverlay = connect(
  mapStateToProps,
  null
)(HousesOverlay)

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          houseOptions: allWordpressAcfOptions {
            edges {
              node {
                options {
                  exteriors_description
                  interiors {
                    images {
                      display
                      image {
                        ...ARYImageInline
                      }
                      images {
                        image {
                          ...ARYImageInline
                        }
                      }
                      credit
                    }
                    description
                    image_credits
                  }
                  prices_description
                }
              }
            }
          }
          houses: allWordpressWpHouse {
            edges {
              node {
                wordpress_id
                title
                bedrooms
                acf {
                  overview
                  elevation_images {
                    label
                    image {
                      ...ARYImageHousesElevation
                    }
                    svg {
                      ...FileWPMedia
                    }
                    svg_ratio
                  }
                  exteriors_images {
                    image {
                      ...ARYImageFullScreen
                    }
                  }
                  materials {
                    hide
                    description
                    images {
                      image {
                        ...ARYImageInline
                      }
                    }
                  }
                  floorplan_width
                  floorplan_length
                  floors {
                    label
                    options {
                      image {
                        ...ARYImageInline
                      }
                    }
                  }
                  floorplan_details {
                    label
                    detail
                  }
                  price_amounts {
                    item
                    amount
                  }
                }
              }
            }
          }
          bedrooms: allWordpressWpBedrooms {
            edges {
              node {
                name
                wordpress_id
              }
            }
          }
          details: allWordpressWpDetail {
            edges {
              node {
                wordpress_id
                acf {
                  houses_matrix {
                    link_to_houses_matrix
                    link_to_options_matrix
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const houses = housesFromData(data)
        const bedrooms = bedroomsFromData(data, houses)

        if (!bedrooms || !houses) {
          return null
        }

        const houseOptions = get(data, 'houseOptions.edges.0.node.options')

        const details = get(data, 'details.edges')
        let detailMatrixHousesID = getDetailMatrixHousesID(details)
        let detailMatrixOptionsID = getDetailMatrixOptionsID(details)

        return (
          <ReduxHousesOverlay
            data={data}
            houses={houses}
            bedrooms={bedrooms}
            houseOptions={houseOptions}
            detailMatrixHousesID={detailMatrixHousesID}
            detailMatrixOptionsID={detailMatrixOptionsID}
            {...props}
          />
        )
      }}
    />
  )
}
