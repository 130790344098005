import React 			    from 'react'
import classnames           from 'classnames'

import LinkWrapper         from '../link-wrapper'

import {
    bgColourIsDark
} from '../../lib/_helpers'

export default (props) => {
    const {
        ctas,
        large,
        fullWidth,
        colour
    } = props

    return <div
        className={ classnames('cta-buttons', {
            [`cta-buttons--c-${ colour }`]: colour,
            'cta-buttons--light': colour && !bgColourIsDark(colour),
            'cta-buttons--large': large,
            'cta-buttons--fullWidth': fullWidth
        }) }>
        { ctas.map((_cta, _i) => {
            return <LinkWrapper
                to={ _cta.link }
                onClick={ _cta.onClick ? (e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    _cta.onClick()
                } : null }
                href={ _cta.href }
                key={ _i }
                className="cta-button">
                { _cta.label }
            </LinkWrapper>
        }) }
    </div>
}