import React                from 'react'
import classnames           from 'classnames'
import { connect } 		from 'react-redux'
import {
	get
} from 'lodash'
import {
	toWords
} from 'number-to-words'

import {
	ImageGallery
} from '../../image-gallery'
import Toggle	 			from '../../toggle'

import Panel                from '../panel'
import TabOptions          	from '../tab-options'
import {
	FloorplanTab
} from '../tab-components'
import {
	FloorplanDetails
} from '../tab-components/floorplan'

import breakpoints 			from '../../../lib/constants/breakpoints.json'
import {
	formatTitle,
	renderHTML
} from '../../../lib/_helpers'

class Floorplans extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			activeIndex: 0
		}
	}

	render() {
		const {
			activeIndex
		} = this.state
		const {
			screenSize,
			house
		} = this.props

		const floors = get(house, 'acf.floors')
		const width = get(house, 'acf.floorplan_width')
		const length = get(house, 'acf.floorplan_length')
		const floorplanDetails = get(house, 'acf.floorplan_details')

		const isMobile = screenSize.width <= breakpoints.mobile

		return <React.Fragment>
			<div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-tabContent-content">
				{ floors ? <div className="housesOverlay-rows">
						<div className="housesOverlay-row housesOverlay-tabContent-title">
							<div className="textBlock textBlock--center">
								<h3>{ formatTitle('Floor plans') }</h3>
							</div>
						</div>
						<div className="housesOverlay-row housesOverlay-row--stretch">
							<FloorplanTab
								width={ width }
								length={ length }
								floors={ floors }
								activeIndex={ activeIndex }
								floorplanDetails={ floorplanDetails }
								isMobile={ isMobile } />
						</div>
						{ floorplanDetails && !isMobile ? <div className="housesOverlay-row housesOverlay-bar floorplan-details">
								<div className="housesOverlay-bar-set housesOverlay-bar-set--center">
									<FloorplanDetails floorplanDetails={ floorplanDetails } />
								</div>
							</div> : null }
					</div> : null }
			</div>
			{ floors ? <div className="housesOverlay-row housesOverlay-bar">
					<TabOptions
						className="housesOverlay-bar-set--center"
						activeIndex={ activeIndex }
						tabs={ [
							...floors.map((_floor, _i) => {
								return _floor.label ? _floor.label : `Floor ${ toWords(_i + 1) }`
							})
						] }
						onTabSelect={ (index) => {
							this.setState({
								activeIndex: index
							})
						} } />
				</div> : null }
		</React.Fragment>
	}
}

const mapStateToProps = (state) => {
    return {
    	screenSize: state.ui.screenSize
    }
}

export default connect(mapStateToProps)(Floorplans)