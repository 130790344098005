import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import ARYImage from '../ary-image'
import CloseButton from '../close-button'

import breakpoints from '../../lib/constants/breakpoints.json'
import { fitDimensionsToBox } from '../../lib/mathHelpers'

import { closeOverlay } from '../../redux/actions'

class VideoOverlay extends React.Component {
  constructor(props) {
    super(props)

    this.closeOverlayHandler = this.closeOverlayHandler.bind(this)
  }

  closeOverlayHandler(e) {
    const { dispatch } = this.props

    e.preventDefault()
    e.stopPropagation()

    dispatch(closeOverlay())
  }

  margin() {
    const { headerHeight, screenSize } = this.props

    if (screenSize.width <= breakpoints.mobilePortrait) {
      return 20
    } else if (screenSize.width <= breakpoints.mobile) {
      return 40
    } else {
      return headerHeight
    }
  }

  render() {
    const { image, dispatch, screenSize } = this.props

    const margin = this.margin() * 2

    const dimensions = fitDimensionsToBox(
      {
        width: screenSize.width - margin,
        height: screenSize.height - margin,
      },
      {
        width: image.width,
        height: image.height,
      },
      true
    )

    return (
      <div
        className={classnames('videoOverlay', 'overlay', 'overlay--center')}
        style={{
          width: screenSize.width,
          height: screenSize.height,
        }}
        onClick={this.closeOverlayHandler}
      >
        <div
          className="overlay-window videoOverlay-window"
          onClick={e => {
            e.stopPropagation()
          }}
          style={{
            width: dimensions.width,
            height: dimensions.height,
            marginLeft: dimensions.width * -0.5,
            marginTop: dimensions.height * -0.5,
          }}
        >
          <CloseButton
            iconOnly={true}
            isDark={true}
            onClick={this.closeOverlayHandler}
          />
          <ARYImage
            image={image}
            shouldPlay={true}
            videoOpts={{
              muted: false,
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { ui } = state

  return {
    headerHeight: ui.header.height,
    screenSize: ui.screenSize,
  }
}

export default connect(
  mapStateToProps,
  null
)(VideoOverlay)
