import React                 from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import {
    isFunction
} from 'lodash'

class TabOptions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            activeIndex,
            tabs,
            onTabSelect,
            className
        } = this.props

        return <div
            className={ classnames('housesOverlay-bar-set', className) }>
            <div className="housesOverlay-bar-set-options">
                { tabs && tabs.length > 0 ? tabs.map((_tab, _i) => {
                    return <div
                        key={ _i }
                        className={ classnames('housesOverlay-bar-set-option', {
                            'housesOverlay-bar-set-option--active': activeIndex === _i
                        }) }
                        onClick={ (e) => {
                            e.preventDefault()
                            e.stopPropagation()

                            if(onTabSelect && isFunction(onTabSelect)){
                                onTabSelect(_i)
                            }
                        } }
                        >
                        { _tab }
                    </div>
                }) : null }
            </div>
        </div>
    }
}

export default TabOptions