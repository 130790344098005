import React  	               from 'react'
import ReactDOM                from 'react-dom'
import requestFrame            from 'request-frame'
import getNodeDimensions       from 'get-node-dimensions'
import PS 						from 'publish-subscribe-js'
import {
	each,
	keys
} from 'lodash'

import Context 	from './context'

import {
	RESIZE
} from '../../lib/pubSubEvents'

class Provider extends React.Component {
	constructor(props) {
		super(props)

		this.onScroll = this.onScroll.bind(this)
		this.checkScrollUpdate = this.checkScrollUpdate.bind(this)

		this.state = {
			left: 0,
			top: 0,
			width: 0,
			height: 0,
			scrollPos: 0
		}
	}

	componentDidMount(){
		this.resizeKey = PS.subscribe(RESIZE, this.checkScrollUpdate)

		this.checkScrollUpdate()
	}

	componentWillUnmount() {
		if(this.resizeKey){ PS.unsubscribe(RESIZE, this.resizeKey) }
		this.cancelScrollCheck()
	}

	cancelScrollCheck(){
		if(this.scrollCheckLoopID){
			const cancel = requestFrame('cancel')
			cancel(this.scrollCheckLoopID)
		}
	}

	onScroll(){
		this.cancelScrollCheck()

        const request = requestFrame('request')
        this.scrollCheckLoopID = request(this.checkScrollUpdate)
	}

	checkScrollUpdate(){
		const el = ReactDOM.findDOMNode(this.el)
	    const elDimensions = getNodeDimensions(el)

	   	let newState = {}
	   	each([
	   		'left',
	   		'top',
	   		'width',
	   		'height',
	   		'scrollPos'
	   	], (_o) => {
	   		const _value = _o === 'scrollPos' ? el.scrollTop : elDimensions[_o]
	   		if(this.state[_o] !== _value){
	   			newState[_o] = _value
	   		}
	   	})

	   	if(keys(newState).length > 0){
	   		this.setState(newState)
	   	}
	}

	render(){
		const {
			children,
			className
		} = this.props

		return <Context.Provider value={ this.state }>
			<div
				className={ className }
				ref={ (el) => { this.el = el } }
				onScroll={ this.onScroll }>
				{ children }
			</div>
		</Context.Provider>
	}
}

export default Provider