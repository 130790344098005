import React                 from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import {
    TransitionGroup,
    CSSTransition
} from 'react-transition-group'
import {
    isFunction
} from 'lodash'

import SidePanel         from '../side-panel'

import PanelBg           from '../panel-bg'

class Panel extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            onClose,
            bool,
            title,
            subtitle,
            textContent,
            textContentClassName,
            titleInText,
            ignoreSettingStyle,
            panelClassName,
            panelBgClassName
        } = this.props

        return <React.Fragment>
            { bool ? <PanelBg
                className={ classnames('housesOverlay-bg', panelBgClassName) }
                onClick={ onClose } /> : null }
            <CSSTransition
				in={ bool }
				unmountOnExit={ true }
				classNames='oT-'
				timeout={{
					enter: 900,
					exit: 600
                }}>
                <SidePanel
                    panelSide="right"
                    ignoreHeader={ true }
                    colour="purpleLight"
                    title={ title }
                    subtitle={ subtitle }
                    textContent={ textContent }
                    className={ panelClassName }
                    textContentClassName={ textContentClassName }
                    titleInText={ titleInText }
                    ignoreSettingStyle={ ignoreSettingStyle }
                    onClose={ onClose } />
            </CSSTransition>
        </React.Fragment>
    }
}

export default Panel