import React                from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import {
	get
} from 'lodash'

import InlineImage 			from '../inline-image'
import TypeformForm 		from '../typeform-embed'

import {
	formatImageForARYImage,
	renderHTML
} from '../../lib/_helpers'

class StoryText extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			introduction,
			rows,
			footer
		} = this.props

		return <div className="storyText">
			{ introduction ? <div className="storyText-row storyText-row--introduction">
					<div className="textBlock textBlock--mid">
						{ renderHTML(introduction) }
					</div>
				</div> : null }
			{ rows && rows.length > 0 ? rows.map((_row, _i) => {
				let _type = ''
				let _content = ''
				let _className = ''

				switch(_row.__typename){
					case 'WordPressAcf_story_text':
					case 'WordPressAcf_text':
						_type = 'text'
						_content = _row.text ? <div className="textBlock">
							{ renderHTML(_row.text) }
						</div> : null
						break
					case 'WordPressAcf_story_subheading':
					case 'WordPressAcf_subheading':
						_type = 'subheading'
						_content = <div className="textBlock textBlock--subhead">
							{ _row.title ? <h3>{ _row.title }</h3> : null }
							{ _row.subtitle ? <h4>{ _row.subtitle }</h4> : null }
						</div>
						_className = classnames({
							'storyText-row--subheading-secondary': _row.display_secondary
						}, _className)
						break
					case 'WordPressAcf_story_quote':
					case 'WordPressAcf_quote':
						_type = 'quote'
						_content = <div className="textBlock textBlock--center textBlock--quote">
							{ _row.quote ? <p>{ `“${ renderHTML(_row.quote) }”` }</p> : null }
							{ _row.from ? <p className="p--from">{ renderHTML(_row.from) }</p> : null }
						</div>
						_className = classnames({
							'storyText-row--quote-hasBoth': _row.quote && _row.from
						}, _className)
						break
					case 'WordPressAcf_image':
						const _image = formatImageForARYImage(_row.image)
						_type ='image'
						_content = _image ? <InlineImage image={ _image } /> : null
						break
					case 'WordPressAcf_story_typeform':
						const _typeformURL = get(_row, 'typeform_url')
						_type = 'typeform'
						_content = _typeformURL ? <TypeformForm url={ _typeformURL } /> : null
						break
				}

				return <div
					key={ _i }
					className={ classnames('storyText-row', `storyText-row--${ _type }`, _className) }>
					{ _content }
				</div>
			}) : null }
			{ footer ? <div className="storyText-row storyText-row--footer">
					<div className="textBlock textBlock--small">
						{ renderHTML(footer) }
					</div>
				</div> : null }
		</div>

	}
}

export default StoryText