import React                from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import { connect }          from 'react-redux'
import {
	isFinite,
	isFunction,
	times
} from 'lodash'

import {
	updateCursor
} from '../../redux/actions'

class NextPrev extends React.Component {
	constructor(props) {
		super(props)

		this.onMouseMove = this.onMouseMove.bind(this)
		this.onMouseLeave = this.onMouseLeave.bind(this)
		this.onTouchStart = this.onTouchStart.bind(this)
		this.onTouchMove = this.onTouchMove.bind(this)
		this.onTouchEnd = this.onTouchEnd.bind(this)

		this.touchStart = null
		this.touchDown = false
		this.touchMoved = false
	}

	componentWillUnmount(){
		const {
			dispatch
		} = this.props

		dispatch(updateCursor(null))
	}

	mapCursorToIcon(dir){
		const {
			vertical
		} = this.props

		if(vertical){
			return dir === 'prev' ? 'up' : 'down'
		} else {
			return dir === 'prev' ? 'left' : 'right'
		}
	}

	touchFromEvent(e){
		const {
			vertical
		} = this.props
		return vertical ? e.touches[0].clientY : e.touches[0].clientX
	}

	onTouchStart(e){
		this.touchDown = true
		this.touchStart = this.touchFromEvent(e)
	}

	onTouchMove(e){
		if(!this.touchDown){ return }

		const touch = this.touchFromEvent(e)
		const diff = this.touchStart - touch

		if(Math.abs(diff) > 25){
			if(!this.touchMoved){
				this.navDirection(diff < 0 ? 'prev' : 'next')
			}
			this.touchMoved = true
		}
	}

	onTouchEnd(e){
		this.touchStart = null
		this.touchDown = false
		this.touchMoved = false
	}

	onMouseMove(e, dir) {
		const {
			dispatch
		} = this.props

		const icon = this.mapCursorToIcon(dir)
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop
		dispatch(updateCursor(icon, e.pageX, e.pageY - scrollTop))
	}

	onMouseLeave(e) {
		const {
			dispatch
		} = this.props

		dispatch(updateCursor(null))
	}

	onClick(e, dir) {
		this.navDirection(dir)
	}

	navDirection(dir){
		const {
			onNavDirection
		} = this.props

		if(onNavDirection && isFunction(onNavDirection)){
			onNavDirection(dir === 'prev' ? -1 : 1)
		}
	}

	render() {
		const {
			hasTouch,
			vertical,
			className
		} = this.props

		return <div
			onTouchStart={ this.onTouchStart }
			onTouchMove={ this.onTouchMove }
			onTouchEnd={ this.onTouchEnd }
			onTouchCancel={ this.onTouchCancel }
			className={ classnames('nextPrev', {
				'nextPrev--vertical': vertical
			}, className) }>
			{
				['prev', 'next'].map((_dir, _i) => {
					return <div
						key={ _i }
						className={ classnames('nextPrev-nav', 'nextPrev-nav--' + _dir) }
						onMouseMove={ hasTouch ? null : (e) => {
							this.onMouseMove(e, _dir)
						} }
						onMouseLeave={ hasTouch ? null : (e) => {
							this.onMouseLeave(e)
						} }
						onClick={ (e) => {
							this.onClick(e, _dir)
						} } />
				})
			}
		</div>
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

	return {
		hasTouch: ui.hasTouch,
		cursor: ui.cursor
	}
}

export default connect(mapStateToProps, null)(NextPrev)