import React 		from 'react'
import {
	connect
} from 'react-redux'
import {
	isFinite
} from 'lodash'

import {
	openOverlay
} from '../../redux/actions'

export default connect()((props) => {
	const {
		detailMatrixHousesID,
		detailMatrixOptionsID,
		dispatch
	} = props

	if(!isFinite(detailMatrixHousesID) && !isFinite(detailMatrixOptionsID)){ return null }

	return <p><strong>
		{ isFinite(detailMatrixHousesID) ? <React.Fragment>For more information on each of our houses and how they compare visit our <a
			href="#"
			onClick={ (e) => {
				e.preventDefault()
				e.stopPropagation()

				dispatch(openOverlay({type: 'detail', id: detailMatrixHousesID}))
			} }>Houses' matrix here</a>.</React.Fragment> : null }
		{ isFinite(detailMatrixHousesID) && isFinite(detailMatrixOptionsID) ? <span>&nbsp;</span> : null }
		{ isFinite(detailMatrixOptionsID) ? <React.Fragment>To see our Customer options visit our <a
			href="#"
			onClick={ (e) => {
				e.preventDefault()
				e.stopPropagation()

				dispatch(openOverlay({type: 'detail', id: detailMatrixOptionsID}))
			} }>Options' matrix here</a>.</React.Fragment> : null }
	</strong></p>
})