import React 			    from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import { 
    withContentRect
} from 'react-measure'
import {
    isArray
} from 'lodash'

import ARYImage             from '../ary-image'
import Toggle               from '../toggle'

import {
    imageIsPortrait
} from '../../lib/mathHelpers'

const ImageSingle = (props) => {
    const {
        contain,
        containDifferentRatio,
        image,
        imageAlignment
    } = props

    let _contain = contain
    if(!_contain && containDifferentRatio){
        _contain = imageIsPortrait(image)
    }

    return <ARYImage
        contain={ _contain }
        imageAlignment={ imageAlignment }
        image={ image }
        />
}

const ImageSwapSvg = withContentRect('bounds')((props) => {
    const {
        measureRef,
        contentRect,
        image,
        contain,
        containDifferentRatio,
        imageAlignment
    } = props

    const showSvg = contentRect.bounds.width > 1500

    return <div className="imageGallery-svgWrap"
        ref={ measureRef }>
        <ImageSingle
            key={ showSvg ? 'svg' : 'img' }
            image={ showSvg ? image.svg : image.image }
            contain={ contain }
            containDifferentRatio={ containDifferentRatio }
            imageAlignment={ imageAlignment }
            />
    </div>
})

const ImageVignette = (props) => {
    const {
        images
    } = props

    const inColumn = Math.ceil(images.length / 2)
    let split = inColumn
    let columns = []
    let row = []
    for(let i = 0; i < images.length; i++){
        row.push(images[i])
        if(i >= split - 1){
            columns.push(row)
            row = []
            split = Math.min(split + inColumn, images.length)
        }
    }

    return <div className="imageGallery-vignette">
        { columns.map((_col, _i) => {
            return <div
                key={ _i }
                className="imageGallery-vignette-col">
                { _col.map((_image, _j) => {
                    return <div
                        key={ `${ _i }-${ _j }` }
                        className="imageGallery-vignette-row">
                        <ARYImage
                            image={ _image } />
                    </div>
                }) }
            </div>
        }) }
    </div>
}

const ImageSlideWrap = (props) => {
    return <div className="imageGallery-slide">
        { props.children }
    </div>
}

const ImageSlide = (props) => {
    const {
        image,
        contain,
        containDifferentRatio,
        imageAlignment,
        swapSvg
    } = props

    const _image = image.image ? image.image : image
    
    let display = 'image'
    if(isArray(_image)){
        display = 'vignette'
    } else if(swapSvg && image.image && image.svg){
        display = 'swapSvg'
    }
    let content = null

    const otherProps = {
        contain,
        containDifferentRatio,
        imageAlignment
    }

    switch(display){
        case 'vignette':
            content = <ImageVignette
                images={ _image }
                />
            break
        case 'swapSvg':
            content = <ImageSwapSvg
                image={ image }
                { ...otherProps }
                />
            break
        default:
            content = <ImageSingle
                image={ _image }
                { ...otherProps }
                />
    }
    
    return <div className="imageGallery-slide">
        { content ? content : null }
        { image.credit ? <div className="imageGallery-slide-credit">{ image.credit }</div> : null }
    </div>
}

class ImageGallery extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            activeIndex,
            contain,
            imageAlignment,
            containDifferentRatio,
            toggleClassName,
            swapSvg,
            images
        } = this.props

        return <div
            className="imageGallery">
            { images && images.length > 0 ? <Toggle
                activeToggle={ activeIndex }
                className={ toggleClassName ? toggleClassName : 'toggleWrapper--fadeOnly' }
                stretch={ true }
                >
                { images.map((_image, _i) => {
                    return <ImageSlide
                        key={ _i }
                        image={ _image }
                        { ...this.props } />
                    
                }) }
            </Toggle> : null }
        </div>
    }
}

export default ImageGallery