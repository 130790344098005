import {
	isArray,
	each,
	find
} from 'lodash'

export const overlayLayers = ['newsletter', 'detail', 'base']

export const getLayerForOverlayType = (type) => {
	switch(type){
		case 'detail':
			return 'detail'
		case 'newsletter':
			return 'newsletter'
		default:
			return 'base'
	}
}

export const getOverlayForLayer = (overlay, layer) => {
	if(!isArray(overlay)){
		return null
	} else {
		return find(overlay, (o) => {
			return layer === getLayerForOverlayType(o.type)
		})
	}
}

export const manageOverlayStateForOpen = (data, state) => {
	const newLayer = getLayerForOverlayType(data.type)

	let newLayers = []
	each(overlayLayers, (_layer, _i) => {
		if(_layer === newLayer){
			newLayers.push(data)
		} else {
			const _existingLayer = getOverlayForLayer(state, _layer)
			if(_existingLayer){
				newLayers.push(_existingLayer)
			}
		}
	})

	return newLayers.length <= 0 ? null : newLayers
}

export const manageOverlayStateForClose = (type, state) => {
	const newLayer = getLayerForOverlayType(type)

	let newLayers = []
	each(overlayLayers, (_layer, _i) => {
		if(_layer !== newLayer){
			const _existingLayer = getOverlayForLayer(state, _layer)
			if(_existingLayer){
				newLayers.push(_existingLayer)
			}
		}
	})

	return newLayers.length <= 0 ? null : newLayers 
}