import React 		from 'react'
import { connect } 	from 'react-redux'
import {
	Link
} from 'gatsby'

import {
	dismissCookie
} from '../../redux/actions'

import {
	formatLink
} from '../../lib/formatLink'

import CTAButtons from '../cta-buttons'

export default connect()((props) => {
	const {
		dispatch
	} = props

	return <div className="cookieNotice">
		<div className="textBlock textBlock--small">
			<p>We use cookies on this site to enhance your user experience. By continuing to use this site you are giving your consent for us to set cookies. You can find more about our use of cookies <Link to={ formatLink('/terms-of-use') }>here</Link>.</p>
		</div>
		<CTAButtons
			colour="white"
			ctas={ [
				{
					label: 'Continue',
					onClick: () => {
						dispatch(dismissCookie())
					}
				}
			] } />
	</div>
})