import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { StaticQuery, graphql, Link } from 'gatsby'
import { get, findIndex, isFinite, isFunction } from 'lodash'
import PS from 'publish-subscribe-js'

import { toggleMenu, closeOverlay, openOverlay } from '../../redux/actions'

import breakpoints from '../../lib/constants/breakpoints.json'
import { bgColourIsDark } from '../../lib/_helpers'
import { formatLink, linkSameMinusHash } from '../../lib/formatLink'
import { clipPathFromPath, pathFromRect } from '../../lib/mathHelpers'
import { HASH_CHANGE } from '../../lib/pubSubEvents'

const triggerMouseEvent = (cb, index) => {
  if (cb && isFunction(cb)) {
    cb(index)
  }
}

export const HeaderLink = ({
  title,
  link,
  active,
  elType,
  index,
  onMouseEnterNavItem,
  location,
  onMouseLeaveNavItem,
  action,
  dispatch,
  className,
  onClick,
}) => {
  const _link = link ? formatLink(link) : null
  const _onClick = onClick
    ? onClick
    : action && isFunction(action)
    ? (e) => {
        e.preventDefault()
        e.stopPropagation()

        action(dispatch)
      }
    : (e) => {
        if (linkSameMinusHash(location.href, _link)) {
          PS.publish(HASH_CHANGE, {
            href: _link,
            animate: true,
          })
        }
      }

  const el = <span>{title}</span>
  const El = elType ? elType : 'li'

  return (
    <El
      className={className}
      onMouseEnter={() => {
        triggerMouseEvent(onMouseEnterNavItem, index)
      }}
      onMouseLeave={() => {
        triggerMouseEvent(onMouseLeaveNavItem, index)
      }}
    >
      {_link ? (
        <Link
          className={classnames({
            'nav-links-link--active': active,
          })}
          onClick={_onClick}
          to={_link}
        >
          {el}
        </Link>
      ) : (
        <span onClick={_onClick}>{el}</span>
      )}
    </El>
  )
}

export const HeaderNavLinks = ({
  links,
  location,
  onMouseEnter,
  onMouseLeave,
  onMouseEnterNavItem,
  onMouseLeaveNavItem,
  dispatch,
}) => {
  return (
    <div
      className="nav-links"
      onMouseEnter={() => {
        triggerMouseEvent(onMouseEnter)
      }}
      onMouseLeave={() => {
        triggerMouseEvent(onMouseLeave)
      }}
    >
      <ul>
        {links
          ? links.map((_link, _i) => {
              return (
                <HeaderLink
                  key={_i}
                  onMouseEnterNavItem={onMouseEnterNavItem}
                  onMouseLeaveNavItem={onMouseLeaveNavItem}
                  index={_i}
                  dispatch={dispatch}
                  active={location ? _link.link === location.pathname : false}
                  location={location}
                  {..._link}
                />
              )
            })
          : null}
      </ul>
    </div>
  )
}

class HeaderNav extends React.Component {
  constructor(props) {
    super(props)
  }

  clipPath() {
    const {
      headerHeight,
      screenSize,
      isTransitioning,
      amountTransitioned,
      colour,
      transitioningFromColour,
    } = this.props

    if (isTransitioning && isFinite(amountTransitioned)) {
      const rectFrom = [0, headerHeight]
      const rectTo = [screenSize.width, headerHeight - amountTransitioned]

      if (colour === transitioningFromColour) {
        rectFrom[1] = 0
      }
      const path = pathFromRect(rectFrom, rectTo)
      return clipPathFromPath(path)
    } else {
      return null
    }
  }

  render() {
    const {
      data,
      dispatch,
      menuOpen,
      screenSize,
      overlay,
      location,
      colour,
      className,
      links,
      activeNavItem,
      activeLocation,
      mobileNav,
      onMouseEnterNavItem,
      onMouseLeaveNavItem,
      onMouseEnterSubNav,
      onMouseLeaveSubNav,
    } = this.props

    const activeSubNavIndex = isFinite(activeNavItem)
      ? activeNavItem
      : isFinite(activeLocation)
      ? activeLocation
      : null
    const activeSubNav = isFinite(activeSubNavIndex)
      ? get(links, `${activeSubNavIndex}.subLinks`)
      : null

    const clipPath = this.clipPath()
    const styles = {
      WebkitClipPath: clipPath,
      clipPath: clipPath,
    }

    const enquiryOverlayOpen = !!overlay && overlay.type === 'enquiry'

    return (
      <div
        className={classnames(
          'headerNav',
          {
            'headerNav--isDark': colour === 'dark' || bgColourIsDark(colour),
            'headerNav--mobile': mobileNav,
            'headerNav--menuOpen': menuOpen,
            [`bg--${colour}`]: colour !== 'dark' && colour !== 'light',
          },
          className
        )}
        style={styles}
      >
        <nav>
          <Link to="/" className="nav-logo nav-margin">
            <div className="nav-logo-logo"></div>
          </Link>
          {mobileNav ? (
            <div
              className="nav-hamburger nav-margin"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                dispatch(toggleMenu(!menuOpen))
              }}
            >
              <div className="nav-hamburger-cont">
                <div />
                <div />
                <div />
              </div>
            </div>
          ) : (
            <React.Fragment>
              <HeaderNavLinks
                onMouseEnterNavItem={onMouseEnterNavItem}
                onMouseLeaveNavItem={onMouseLeaveNavItem}
                links={links}
                location={location}
                dispatch={dispatch}
              />
              <div
                className={classnames('nav-enquiry nav-margin', {
                  'nav-enquiry--open': enquiryOverlayOpen,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (enquiryOverlayOpen) {
                    dispatch(closeOverlay())
                  } else {
                    dispatch(
                      openOverlay({
                        type: 'enquiry',
                      })
                    )
                  }
                }}
              >
                <div className="nav-enquiry-monogram"></div>
                <div className="nav-enquiry-text nav-enquiry-text--open">
                  <span>Make an enquiry</span>
                </div>
                <div className="nav-enquiry-text nav-enquiry-text--close">
                  <span>Close enquiry</span>
                </div>
              </div>
            </React.Fragment>
          )}
        </nav>
        {activeSubNav ? (
          <nav>
            <HeaderNavLinks
              onMouseEnter={onMouseEnterSubNav}
              onMouseLeave={onMouseLeaveSubNav}
              location={location}
              links={activeSubNav}
              dispatch={dispatch}
            />
          </nav>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    headerHeight: ui.header.height,
    activeNavItem: ui.header.activeNavItem,
    menuOpen: ui.menuOpen,
    screenSize: ui.screenSize,
    overlay: ui.overlay,
  }
}

export default connect(mapStateToProps, null)(HeaderNav)
