import * as Promise from 'bluebird'

Promise.config({
    cancellation: true
})

export default (arr) => {
    let newImages = []
    let loadedImages = 0

    return new Promise((resolve, reject, onCancel) => {
        const imageLoaded = () => {
            loadedImages++
            if(loadedImages >= arr.length){
                resolve()
            }
        }
        for(let i = 0; i < arr.length; i++){
            newImages[i] = new Image()
            newImages[i].src = arr[i]
            newImages[i].onload = () => {
                imageLoaded()
            }
            newImages[i].onerror = () => {
                imageLoaded()
            }
        }
    })
}