import React                from 'react'
import classnames           from 'classnames'
import {
	get,
	filter
} from 'lodash'
import {
	toWords
} from 'number-to-words'

import {
	ImageGallery
} from '../../image-gallery'
import Toggle	 			from '../../toggle'

import MatrixText 			from '../matrix-text'
import Panel				from '../panel'
import TabOptions           from '../tab-options'
import {
	GalleryTab,
	SplitTab
} from '../tab-components'

import {
	formatImageForARYImage,
	renderHTML
} from '../../../lib/_helpers'

const exteriorTabs = [
	'Exterior info',
	'Views',
	'Alternatives'
]

class Exteriors extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			activeIndex: 0,
			overviewActive: false
		}
	}

	render() {
		const {
			activeIndex,
			overviewActive
		} = this.state
		const {
			house,
			houseOptions,
			detailMatrixHousesID,
			detailMatrixOptionsID
		} = this.props

		const overview = get(houseOptions, 'exteriors_description')
		const exteriorImages = get(house, 'acf.exteriors_images')
		const materials = get(house, 'acf.materials')

		const _exteriorTabs = filter(exteriorTabs, (_tab, _i) => {
			if(
				_tab === 'Alternatives' &&
				(
					!materials ||
					materials.hide
				)
			){
				return false
			} else {
				return true
			}
		})

		return <React.Fragment>
			<div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-tabContent-content">
				<Toggle
					activeToggle={ activeIndex }
					className="toggleWrapper--fadeInOut"
					stretch={ true }
					>
					<GalleryTab
						title="Exterior"
						images={ exteriorImages.map((_exteriorImage, _i) => {
                            return formatImageForARYImage(_exteriorImage.image)
                        }) }
						/>
					<SplitTab
						title="Alternatives"
						textContent={ materials.description }
						images={ materials.images.map((_material, _i) => {
                            return formatImageForARYImage(_material.image)
                        }) } />
				</Toggle>
			</div>
			<div className="housesOverlay-row housesOverlay-bar">
				<TabOptions
					className="housesOverlay-bar-set--center"
					activeIndex={ overviewActive ? 0 : activeIndex + 1 }
					tabs={ _exteriorTabs }
					onTabSelect={ (index) => {
						const _tab = _exteriorTabs[index]
						if(_tab === 'Exterior info'){
							this.setState({
								overviewActive: true
							})
						} else {
							this.setState({
								activeIndex: index - 1
							})
						}
					} } />
			</div>
			<Panel
				bool={ overviewActive }
				onClose={ () => {
					this.setState({
						overviewActive: false
					})
				} }
				ignoreSettingStyle={ true }
				panelClassName="housesOverlay-tabContent-panel"
				title="Exterior info"
				titleInText={ true }
				textContent={ overview ? <React.Fragment>
						{ renderHTML(overview) }
						<MatrixText
							detailMatrixHousesID={ detailMatrixHousesID }
							detailMatrixOptionsID={ detailMatrixOptionsID } />
					</React.Fragment> : null }
				/>
		</React.Fragment>
	}
}

export default Exteriors