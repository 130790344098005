import React                from 'react'
import classnames           from 'classnames'

import Breadcrumbs 			from '../../breadcrumbs'
import {
	ImageGallery
} from '../../image-gallery'
import NextPrev from '../../next-prev'

import {
	formatTitle,
	wrapIndexShift,
	renderHTML
} from '../../../lib/_helpers'

class GalleryTab extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectIndex = this.onSelectIndex.bind(this)
		this.onNavDirection = this.onNavDirection.bind(this)

		this.state = {
			activeIndex: 0
		}
	}

	onSelectIndex(index) {
		this.setState({
            activeIndex: index
        })
	}

	onNavDirection(dir){
		const {
			activeIndex
		} = this.state
		const {
			images
		} = this.props

		this.setState({
			activeIndex: wrapIndexShift(activeIndex, dir, images.length)
		})
	}

	render() {
		const {
			activeIndex
		} = this.state
		const {
			title,
			images
		} = this.props

		return <div className="housesOverlay-rows housesOverlay-tabComponent housesOverlay-tabComponent--gallery">
			{ title ? <div className="housesOverlay-row housesOverlay-tabContent-title">
				<div className="textBlock textBlock--center">
					<h3>{ formatTitle(title) }</h3>
				</div>
			</div> : null }
			{ images ? <div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-tabContent-gallery">
				{ images.length > 1 ? <NextPrev
					onNavDirection={ this.onNavDirection } /> : null }
				<Breadcrumbs
					activeIndex={ activeIndex }
					length={ images.length }
					dark={ true }
					onCrumbSelect={ this.onSelectIndex }
					/>
				<div className="housesOverlay-tabContent-gallery-wrapper">
					<ImageGallery
						activeIndex={ activeIndex }
						contain={ true }
						imageAlignment="bottom"
						toggleClassName="toggleWrapper--fadeInOut"
						images={ images } />
				</div>
			</div> : null }
		</div>
	}
}

export default GalleryTab