const _ = require('lodash')

const HASH = '#'
const SLASH = '/'

const formatLink = (slug, pageType = null) => {
  let link = SLASH

  let path = pathForPageType(pageType)
  if (path) {
    link = addSlugToLink(path, link)
  }

  if (linkHasHash(slug)) {
    const hashParts = splitIntoTwo(slug, HASH)
    if (!hashParts) {
      link = addSlugToLink(slug, link)
    } else if (hashParts.length === 1) {
      link = `/${HASH}/${addSlugToLink(hashParts[0])}`
    } else {
      link = `${addSlugToLink(hashParts[0], link)}${HASH}/${addSlugToLink(
        hashParts[1]
      )}`
    }
  } else {
    link = addSlugToLink(slug, link)
  }

  return link
}

const addSlugToLink = (slug, link = '') => {
  if (slug.charAt(0) === SLASH) {
    slug = slug.slice(1)
  }

  link += slug

  if (link.slice(-1) !== SLASH) {
    link += SLASH
  }

  return link
}

const pathForPageType = (pageType) => {
  switch (pageType) {
    default:
      return null
  }
}

const linkHasHash = (link) => {
  if (!link) {
    return false
  }
  const hashParts = splitIntoTwo(link, HASH)
  return (hashParts && hashParts.length > 1) || link.substring(0, 1) === HASH
}

const hashPartsFromLink = (link) => {
  if (!linkHasHash(link)) {
    return null
  }
  const hashString =
    link.substring(0, 1) === HASH
      ? link.substring(1)
      : splitIntoTwo(link, HASH)[1]
  return splitIntoTwo(hashString, SLASH)
}

const splitIntoTwo = (str, spl) => {
  if (!str || !_.isString(str)) {
    return null
  }
  const _str = str.trim()
  if (_str.length <= 0) {
    return null
  }

  const parts = _.compact(_str.split(spl))

  if (parts.length <= 0) {
    return null
  } else if (parts.length === 1) {
    return parts
  } else {
    return [parts[0], parts.slice(1).join(str)]
  }
}

const linkAsPath = (link) => {
  const url = process.env.GATSBY_FRONTEND_URL
  return typeof link === 'string'
    ? link.startsWith(url)
      ? link.replace(url, '')
      : link
    : ''
}

const linkSameMinusHash = (a, b) => {
  return linkAsPath(a).split(HASH)[0] === linkAsPath(b).split(HASH)[0]
}

const overlayKeys = ['newsletter', 'detail', 'house', 'enquiry']

const isOverlayHash = (hashParts) => {
  return hashParts ? _.includes(overlayKeys, hashParts[0]) : false
}

const overlayIDFromString = (str, arrKey, props) => {
  const { detailMatrixHousesID, detailMatrixOptionsID, details, houses } = props

  if (!str) {
    return null
  }

  if (arrKey === 'details') {
    switch (str) {
      case 'houses':
        return detailMatrixHousesID
      case 'options':
        return detailMatrixOptionsID
      default:
        return overlayIDOrSlug(str, details)
    }
  } else if (arrKey === 'houses') {
    return overlayIDOrSlug(str, houses)
  } else {
    return null
  }
}

const overlayIDOrSlug = (str, arr) => {
  const idFromStr = parseInt(str)

  if (_.isFinite(idFromStr)) {
    const foundObj = _.find(arr, { wordpress_id: idFromStr })
    if (foundObj) {
      return idFromStr
    }
  }

  return overlayIDFromSlug(str, arr)
}

const overlayIDFromSlug = (slug, arr) => {
  const foundObj = _.find(arr, { slug: slug })
  if (foundObj) {
    return foundObj.wordpress_id
  } else {
    return null
  }
}

const overlayDispatchObjFromHash = (hashParts, props) => {
  const arrKey = overlayArrKeyFromHash(hashParts)
  const id = arrKey ? overlayIDFromString(hashParts[1], arrKey, props) : null

  return {
    type: overlayTypeFromKey(hashParts[0]),
    id: id,
  }
}

const overlayTypeFromKey = (hashKey) => {
  switch (hashKey) {
    case 'house':
      return 'houses'
    default:
      return hashKey
  }
}

const overlayArrKeyFromHash = (hashParts) => {
  switch (hashParts[0]) {
    case 'house':
    case 'detail':
      return `${hashParts[0]}s`
    default:
      return null
  }
}

const getInternalLink = (link) => {
  if (!link || !_.isString(link)) {
    return null
  }

  const firstLetter = link.substring(0, 1)
  const replaced = linkAsPath(link)

  if (replaced !== link) {
    return replaced
  } else if (firstLetter === HASH || firstLetter === SLASH) {
    return link
  } else {
    return null
  }
}

exports.formatLink = formatLink
exports.linkSameMinusHash = linkSameMinusHash
exports.linkHasHash = linkHasHash
exports.hashPartsFromLink = hashPartsFromLink
exports.isOverlayHash = isOverlayHash
exports.overlayDispatchObjFromHash = overlayDispatchObjFromHash
exports.getInternalLink = getInternalLink
