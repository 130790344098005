import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { isFunction } from 'lodash'

import CloseButton from '../close-button'

import breakpoints from '../../lib/constants/breakpoints.json'
import { formatTitle, bgColourIsDark, renderHTML } from '../../lib/_helpers'

import { closeOverlay } from '../../redux/actions'

class SidePanel extends React.Component {
  constructor(props) {
    super(props)
  }

  width() {
    const { fullWidthMobile, screenSize } = this.props

    if (screenSize.width <= breakpoints.mobilePortrait) {
      return fullWidthMobile
        ? screenSize.width
        : Math.min(375, screenSize.width - 40)
    } else if (screenSize.width <= breakpoints.tabletPortrait) {
      return screenSize.width * 0.75
    } else {
      return screenSize.width * 0.5
    }
  }

  render() {
    const {
      title,
      subtitle,
      titleInText,
      textContent,
      textContentClassName,
      colour,
      panelSide,
      ignoreHeader,
      screenSize,
      onClose,
      className,
      ignoreSettingStyle,
      noPadding,
      dispatch,
      children,
    } = this.props

    const contClassName = classnames('cont cont--half', {
      'cont--half-left': panelSide === 'left',
    })

    const titleSubtitle =
      title || subtitle ? (
        <React.Fragment>
          {title ? <h3>{formatTitle(renderHTML(title))}</h3> : null}
          {subtitle ? <h5>{subtitle}</h5> : null}
        </React.Fragment>
      ) : null

    return (
      <div
        className={classnames(
          'sidePanel',
          {
            [`sidePanel--${panelSide}`]: panelSide,
            'sidePanel--ignoreHeader': ignoreHeader,
            'sidePanel--noPadding': noPadding,
            'sidePanel--showBorder': colour && colour === 'offWhite',
            'txt--white': colour ? bgColourIsDark(colour) : false,
          },
          className
        )}
        style={
          !ignoreSettingStyle
            ? {
                width: this.width(),
                height: screenSize.height,
              }
            : null
        }
      >
        <div className="sidePanel-content">
          {titleSubtitle && !titleInText ? (
            <div className="sidePanel-content-title">
              <div className={contClassName}>
                <div className="textBlock">{titleSubtitle}</div>
              </div>
            </div>
          ) : null}
          <div className="sidePanel-content-content">
            <div className={contClassName}>
              {(titleSubtitle && titleInText) || textContent ? (
                <div className={classnames('textBlock', textContentClassName)}>
                  {titleSubtitle && titleInText ? titleSubtitle : null}
                  {textContent}
                </div>
              ) : null}
              {children ? children : null}
            </div>
          </div>
          <div
            className="sidePanel-content-close"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              if (onClose && isFunction(onClose)) {
                onClose()
              }
            }}
          >
            <div className={contClassName}>
              <CloseButton />
            </div>
          </div>
        </div>
        <div
          className={classnames('sidePanel-bg', {
            [`bg--${colour}`]: colour,
          })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(
  mapStateToProps,
  null
)(SidePanel)
