import React                from 'react'
import classnames           from 'classnames'
import { withContentRect } 	from 'react-measure'
import {
	isFinite
} from 'lodash'

import ARYImage 			from '../../ary-image'
import InlineImage 			from '../../inline-image'
import Toggle 				from '../../toggle'

import {
	formatImageForARYImage,
	renderHTML
} from '../../../lib/_helpers'
import {
	ratioFromImage,
	fitDimensionsToBox
} from '../../../lib/mathHelpers'

export const FloorplanDetails = (props) => {
	const {
		floorplanDetails
	} = props

	return floorplanDetails ? floorplanDetails.map((_detail, _i) => {
		return <div className="floorplan-details-detail"
			key={ _i }>
			{ _detail.label ? <div className="floorplan-details-detail-l">{ _detail.label }</div> : null }
			{ _detail.detail ? <div className="floorplan-details-detail-r">{ renderHTML(_detail.detail) }</div> : null }
		</div>
	}) : null
}

const FloorplanMeasurement = ({ length, vertical }) => {
	return <div className={ classnames('floorplan-option-measurement', {
			'floorplan-option-measurement--vertical': vertical
		}) }>
		<span>{ length }</span>
	</div>
}

const FloorplanImage = withContentRect('client')(
	({image, width, length, measureRef, contentRect, isMobile}) => {
		const dimensions = fitDimensionsToBox(
			{
				width: contentRect.client.width,
				height: contentRect.client.height
			},
			{
				width: image.width,
				height: image.height,
				ratio: 1 / image.aspectRatio
			},
			true
		)

		const imageRatio = ratioFromImage(image)

		return <div
			className="floorplan-option-image"
			ref={ measureRef }>
			{ isMobile ? <div className="floorplan-option-image-spacer"
				style={{
					paddingBottom: `${ imageRatio * 100 }%`
				}}
				/> : null }
			<div className="floorplan-option-image-wrap"
				style={ isFinite(dimensions.width) ? {
					width: dimensions.width,
					height: dimensions.height,
					marginLeft: dimensions.width * -0.5,
					marginTop: dimensions.height * -0.5
				} : null }>
				<FloorplanMeasurement length={ width } />
				<FloorplanMeasurement length={ length } vertical={ true } />
				<ARYImage
					contain={ true }
					image={ image } />
			</div>
		</div>
	}
)

const FloorplanFloor = (props) => {
	const {
		options,
		width,
		length,
		isMobile
	} = props

	return <div className={ classnames('floorplan-floor-options', {
		'floorplan-floor-options--mobile': isMobile
	}) }>
		{ options && options.length > 0 ? options.map((_option, _i) => {
			const _image = formatImageForARYImage(_option.image)

			return <div
				key={ _i }
				className={ classnames('floorplan-option', {
					'floorplan-option--first': !isMobile && _i === 0,
					'floorplan-option--last': !isMobile && _i >= options.length - 1
				}) }>
				{ _image ? <FloorplanImage
					isMobile={ isMobile }
					image={ _image }
					width={ width }
					length={ length } /> : null }
			</div>
		}) : null }
	</div>
}

class FloorplanTab extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			activeIndex,
			floors,
			width,
			length,
			isMobile,
			floorplanDetails
		} = this.props

		return <div className="housesOverlay-tabComponent housesOverlay-tabComponent--floorplan">
			<div className={ classnames('floorplan', 'cont', {
				'floorplan--mobile': isMobile
			}) }>
				{ !isMobile ? <div className="floorplan-spacer" /> : null }
				<div className="floorplan-content">
					<div className="floorplan-column floorplan-floors">
						<Toggle
							activeToggle={ activeIndex }
							className="toggleWrapper--fadeInOut"
							stretch={ true }
							>
							{ floors.map((_floor, _i) => {
								return <div
									key={ _i }
									className="floorplan-floors-wrapper">
									<FloorplanFloor
										width={ width }
										length={ length }
										isMobile={ isMobile }
										options={ _floor.options } />
									{ isMobile ? <React.Fragment>
										<div className="floorplan-spacer floorplan-spacer--bottom" />
										<div className="floorplan-details floorplan-details--inline">
											<FloorplanDetails floorplanDetails={ floorplanDetails } />
										</div>
									</React.Fragment> : null }
								</div>
							}) }
						</Toggle>
					</div>
				</div>
				<div className="floorplan-spacer floorplan-spacer--bottom" />
			</div>
		</div>
	}
}

export default FloorplanTab