import React 			from 'react'
import Measure			from 'react-measure'
import classnames		from 'classnames'
import {
	isFinite,
	isArray,
	isFunction
} from 'lodash'
import {
	TransitionGroup,
	CSSTransition 
} from 'react-transition-group'

import ARYImage 		from '../ary-image'
import InlineImage 		from '../inline-image'

class Toggle extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			direction: 1
		}
	}

	componentWillReceiveProps(prevProps){
		if(prevProps.activeToggle !== this.props.activeToggle){
			let direction = 1
			if(prevProps.activeToggle < this.props.activeToggle){
				direction = -1
			}
			if(direction !== this.state.direction){
				this.setState({
					direction: direction
				})
			}
		}
	}

	componentWillUpdate(prevProps, prevState){
		if(prevProps.activeToggle !== this.props.activeToggle){
			const { onToggle } = this.props
			if(onToggle && isFunction(onToggle)){
				onToggle()
			}
		}
	}

	render() {
		const {
			direction
		} = this.state
		const { 
			children, 
			activeToggle,
			stretch,
			className
		} = this.props

		let activeChild = null
		if(isArray(children)){
			if(activeToggle < children.length){
				activeChild = children[activeToggle]
			} else {
				activeChild = children[0]
			}
		} else {
			activeChild = children
		}

		const classes = classnames('toggleWrapper', className, {
			'toggleWrapper--stretch': stretch,
			'toggleWrapper--prev': direction < 0
		})

		return <div className={ classes }>
			<div className="toggleWrapper-wrap">
				<TransitionGroup>
	      			<CSSTransition
	      				key={ activeToggle }
	      				classNames='toggleFade'
	      				timeout={{
	      					enter: 1300,
	 						exit: 1300
	 					}}>
	 					<div className="toggleWrapper-slide" key={ activeToggle }>
							{ React.cloneElement(activeChild) }
						</div>
					</CSSTransition>
				</TransitionGroup>
			</div>
		</div>
	}

}

export default Toggle