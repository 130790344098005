import React from 'react'
import {
  indexOf,
  get,
  isArray,
  isFinite,
  isObject,
  filter,
  reduce,
} from 'lodash'
import { graphql } from 'gatsby'
import moment from 'moment'
import parse, { domToReact } from 'html-react-parser'

import {
  formatLink,
  isOverlayHash,
  getInternalLink,
  linkHasHash,
  hashPartsFromLink,
  overlayDispatchObjFromHash,
} from './formatLink'

import { openOverlay } from '../redux/actions'

import InlineLink from '../components/inline-link'

export const formatAcfImageForARYImage = (image, size = 'full') => {
  const _image = get(image, `sizes.${size}`)
  return _image
    ? {
        src: _image,
        width: image.sizes[size + '_width'],
        height: image.sizes[size + '_height'],
      }
    : null
}

export const formatWpImageForARYImage = (image) => {
  return image && image.source_url
    ? {
        src: image.source_url,
        width: image.media_details.width,
        height: image.media_details.height,
      }
    : null
}

export const formatImageForARYImage = (image, size = null) => {
  if (!image) {
    return null
  }

  const _gatsbyImage = get(image, 'localFile.childImageSharp')
  if (_gatsbyImage) {
    if (_gatsbyImage.fixed) {
      return {
        ..._gatsbyImage.fixed,
        gatsbyImage: true,
        gatsbyImageType: 'fixed',
      }
    } else if (_gatsbyImage.fluid) {
      return {
        ..._gatsbyImage.fluid,
        gatsbyImage: true,
        gatsbyImageType: 'fluid',
      }
    }
  }

  if (image.source_url) {
    return {
      src: image.source_url,
      width: image.media_details.width,
      height: image.media_details.height,
    }
  }

  const _size = size ? size : 'full'
  const _acfImage = get(image, `sizes.${size}`)
  if (_acfImage) {
    return {
      src: _acfImage,
      width: image.sizes[size + '_width'],
      height: image.sizes[size + '_height'],
    }
  }

  return null
}

export const getImageSrc = (image) => {
  const img = formatImageForARYImage(image)
  const src = img && img.src ? img.src : null
  if (!src) {
    return null
  }
  return frontendPath(src)
}

export const formatSvgAndImage = (image, size = null) => {
  if (!image) {
    return null
  }

  return {
    svg: formatSvg(image),
    image: formatImageForARYImage(image.image, size),
  }
}

export const formatSvgOrImage = (image, size = null) => {
  if (!image) {
    return null
  }

  const _imageType = get(image, 'image_or_svg')
  const svg = formatSvg(image)
  if (_imageType && _imageType === 'svg' && svg) {
    return svg
  } else {
    return formatImageForARYImage(image.image, size)
  }
}

export const formatSvg = (image) => {
  const svg = get(image, 'svg.source_url')
  const _ratio = parseFloat(image.svg_ratio)

  if (!svg) {
    return null
  }

  return {
    svg: svg,
    aspectRatio: isFinite(_ratio) ? _ratio : 1,
  }
}

const joinPaths = (...args) => {
  return args && args.length > 0 && isArray(args)
    ? reduce(
        args,
        (_v, _a, _i) => {
          if (
            _v &&
            _v.length > 0 &&
            _v.slice(_v.length - 1) !== '/' &&
            _a &&
            _a.length > 0 &&
            _a.slice(0, 1) !== '/'
          ) {
            _v = `${_v}/`
          }
          return `${_v}${_a}`
        },
        ''
      )
    : ''
}

const getFrontendUrl = () => {
  if (process.env.GATSBY_FRONTEND_URL) {
    return process.env.GATSBY_FRONTEND_URL
  } else if (typeof window !== 'undefined' && window.FRONTEND_URL) {
    return window.FRONTEND_URL
  } else if (process.env.NODE_ENV === 'production') {
    return 'https://www.kisshouse.co.uk'
  } else {
    return 'http://localhost:8000'
  }
}

export const frontendPath = (path) => {
  return joinPaths(getFrontendUrl(), path)
}

const getApiUrl = () => {
  if (process.env.GATSBY_API_URL) {
    return process.env.GATSBY_API_URL
  } else if (typeof window !== 'undefined' && window.API_URL) {
    return window.API_URL
  } else if (process.env.NODE_ENV === 'production') {
    return 'https://kisshouse-cms.herokuapp.com'
  } else {
    return 'http://127.0.0.1:8080'
  }
}

export const apiPath = (path) => {
  return joinPaths(getApiUrl(), path)
}

export const bgColourIsDark = (colour) => {
  const darkColours = [
    'purple',
    'purpleLight',
    'green',
    'greenDark',
    'orangeDark',
    'orange',
  ]
  return indexOf(darkColours, colour) >= 0
}

export const storyPrefix = (node) => {
  let prefix = ''
  for (let i = 0; i < node.categories.length; i++) {
    if (prefix.length > 0) {
      prefix += ', '
    }
    prefix += node.categories[i].name
  }
  if (prefix.length > 0) {
    prefix += '&nbsp;&nbsp;|&nbsp;&nbsp;'
  }
  prefix += moment(node.date, 'YYYY MMMM DD').format('DD MMMM YYYY')

  return prefix
}

export const formatTitle = (str) => {
  if (!str || str.length <= 0) {
    return ''
  }

  const lastLetter = str[str.length - 1]
  if (lastLetter.match(/^[0-9a-zA-Z]+$/)) {
    return `${renderHTML(str)}.`
  } else {
    return renderHTML(str)
  }
}

export const formatImageArray = (
  images = [],
  key = null,
  creditCheck = true
) => {
  let arr = []

  for (let i = 0; i < images.length; i++) {
    let __image = false

    if (images[i].display && images[i].display === 'vignette') {
      const _images = get(images[i], 'images')
      if (_images) {
        __image = formatImageArray(images[i].images, key, false)
      }
    } else {
      const _image = get(images[i], key)
      if (_image) {
        __image = formatImageForARYImage(_image)
      }
    }

    if (
      __image &&
      (isObject(__image) || (isArray(__image) && __image.length > 0))
    ) {
      arr.push(
        creditCheck
          ? {
              image: __image,
              credit: images[i].credit,
            }
          : __image
      )
    }
  }

  return arr
}

const getImageObjForType = (image, type = null) => {
  if (type === 'wp') {
    return formatWpImageForARYImage(image)
  } else {
    return formatAcfImageForARYImage(image)
  }
}

export const wrapIndexShift = (index, shift, length) => {
  let _index = index + shift
  if (_index >= length) {
    _index = 0
  } else if (_index < 0) {
    _index = length - 1
  }
  return _index
}

export const getFixedStyle = (fixedStatus, offset = 0) => {
  return {
    position: fixedStatus === 'fixed' ? 'fixed' : 'absolute',
    top:
      fixedStatus === 'fixed' ? offset : fixedStatus === 'bottom' ? 'auto' : 0,
    bottom: fixedStatus === 'bottom' ? 0 : null,
  }
}

export const mapStories = (stories) => {
  return stories && stories.length > 0
    ? filter(
        stories.map((_node, _i) => {
          const _prefix = storyPrefix(_node.node)

          return {
            prefix: _prefix,
            featured: _node.node.acf.featured_article,
            title: _node.node.title,
            image: formatImageForARYImage(_node.node.acf.thumbnail),
            body: _node.node.acf.introduction,
            bodyIsHTML: true,
            link: formatLink(_node.node.path, 'post'),
            hide: _node.node.acf.hide_from_stories,
          }
        }),
        (_node, _i) => {
          return !_node.hide
        }
      )
    : null
}

export const renderHTML = (str) => {
  return str
    ? parse(str, {
        replace: ({ name, attribs, children }) => {
          if (!attribs) {
            return
          }

          if (name === 'a') {
            const _href = get(attribs, 'href')
            if (!_href) {
              return
            }

            const _internalLink = getInternalLink(_href)

            if (!!_internalLink) {
              let _onClick = null

              if (linkHasHash(_internalLink)) {
                const _hashParts = hashPartsFromLink(_internalLink)
                if (isOverlayHash(_hashParts)) {
                  _onClick = (props) => {
                    const { dispatch } = props

                    dispatch(
                      openOverlay(overlayDispatchObjFromHash(_hashParts, props))
                    )
                  }
                }
              }

              return (
                <InlineLink
                  onClick={_onClick}
                  internalTo={_internalLink}
                  {...attribs}
                >
                  {domToReact(children)}
                </InlineLink>
              )
            }
          }
        },
      })
    : null
}

const getMetaValueForIndex = (v, values, i, k) => {
  const _v = get(values, `${i}.${k}`)
  return !v && _v ? _v : v
}

export const getMetaValues = (..._values) => {
  let title = null
  let description = null
  let image = null

  const values = [
    ..._values,
    {
      title: 'Kiss House',
      description:
        'Build better. Live better. We believe there’s a better way to create homes. That everyone deserves brilliant housing. That people and quality come first.',
    },
  ]

  for (let i = 0; i < values.length; i++) {
    title = getMetaValueForIndex(title, values, i, 'title')
    description = getMetaValueForIndex(description, values, i, 'description')
    image = getMetaValueForIndex(image, values, i, 'image')

    if (title && description) {
      break
    }
  }

  return {
    title: title ? renderHTML(title) : null,
    description: description ? renderHTML(description) : null,
    image: image ? getImageSrc(image) : null,
  }
}
