import { combineReducers } from 'redux'
import Cookies 				from 'js-cookie'

import breakpoints from '../../../lib/constants/breakpoints.json'

import {
	TOGGLE_MENU,
	TOGGLE_PAGE_INTRO,
	TOGGLE_PAGE_TRANSITIONING,
	RESIZE_SCREEN,
	OPEN_OVERLAY,
	CLOSE_OVERLAY,
	UPDATE_CURSOR,
	DISMISS_COOKIE
} from '../../actions'

import header from './header'

import {
	manageOverlayStateForOpen,
	manageOverlayStateForClose
} from '../../../components/overlay-manager/_helpers'

import {
	screenSizeDefault
} from './defaults'

const menuOpen = (state = false, action) => {
	switch(action.type){
		case TOGGLE_MENU:
			return action.open
		default:
			return state
	}
}

const screenSize = (state = screenSizeDefault, action) => {
	switch(action.type){
		case RESIZE_SCREEN:
			return action.screenSize
		default:
			return state
	}
}

const hasTouch = (state = (typeof document !== 'undefined' && 'ontouchstart' in document.documentElement)) => {
	return state
}

const overlay = (state = null, action) => {
	switch(action.type){
		case OPEN_OVERLAY:
			return manageOverlayStateForOpen(action.data, state)
		case CLOSE_OVERLAY:
			return manageOverlayStateForClose(action.data, state)
		default:
			return state
	}
}

const pageIntro = (state = false, action) => {
	switch(action.type){
		case TOGGLE_PAGE_INTRO:
			return action.bool
		default:
			return state
	}
}

const pageTransitioning = (state = false, action) => {
	switch(action.type){
		case TOGGLE_PAGE_TRANSITIONING:
			return action.bool
		default:
			return state
	}
}

const cursor = (state = null, action) => {
	switch(action.type){
		case UPDATE_CURSOR:
			return action.icon ? {
				icon: action.icon,
				position: action.position
			} : null
		default:
			return state
	}
}

const userHasSetCookie = Cookies.get('kh_cookie_notice')
const hasCookie = (state = !userHasSetCookie, action) => {
	switch(action.type){
		case DISMISS_COOKIE:
			Cookies.set('kh_cookie_notice', true, { expires: 30 })
			return false
		default:
			return state
	}
}

export default combineReducers({
	header,
	hasTouch,
	menuOpen,
	screenSize,
	overlay,
	pageIntro,
	pageTransitioning,
	cursor,
	hasCookie
})