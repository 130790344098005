import React 			from 'react'

import IconButton 		from '../icon-button'

export default (props) => {
	return <IconButton
		{ ...props }
		type="close"
		label="Close"
		/>
}