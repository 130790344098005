import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from 'react-transition-group'
import PS from 'publish-subscribe-js'

import { togglePageTransition } from '../../redux/actions'
import { PAGE_INIT } from '../../lib/pubSubEvents'

class PageTransition extends React.Component {
  render() {
    const { children, location, dispatch } = this.props

    return (
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="pageFade-"
          timeout={{
            enter: 1300,
            exit: 600,
          }}
          onEntering={() => {
            dispatch(togglePageTransition(true))
          }}
          onExited={() => {
            setTimeout(() => {
              dispatch(togglePageTransition(false))
              PS.publish(PAGE_INIT)
            }, 10)
          }}
        >
          <div className="page">{children}</div>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

export default connect(
  null,
  null
)(PageTransition)
