import React                from 'react'
import classnames           from 'classnames'

import Split 				from '../../split'

import {
	renderHTML
} from '../../../lib/_helpers'

export default (props) => {
	const {
		title,
		textContent,
		footerContent,
		images
	} = props

	return <div className="housesOverlay-tabComponent housesOverlay-tabComponent--split">
		<Split
			title={ title }
			textContent={ textContent }
			footerContent={ footerContent }
			images={ images }
			/>
	</div>
}