import React  	               from 'react'
import ReactDOM                from 'react-dom'
import getNodeDimensions       from 'get-node-dimensions'
import { connect }             from 'react-redux'
import {
    keys
} from 'lodash'

import Context 	from './context'

class Consumer extends React.Component {
	constructor(props) {
        super(props)

        this.state = {
            fixed: false,
            left: 0,
            right: 0
        }
    }

    componentDidMount(){
        this.checkLayout()
    }

    componentDidUpdate(){
        this.checkLayout()
    }

    checkLayout(){
        const {
            fixed,
            left,
            right
        } = this.state

        const el = ReactDOM.findDOMNode(this.el)
        const elDimensions = getNodeDimensions(el)

        const newFixed = elDimensions.top <= this.context.top

        let newState = {}

        if(fixed !== newFixed){ newState.fixed = newFixed }
        if(left !== elDimensions.left){ newState.left = elDimensions.left }
        if(right !== elDimensions.right){ newState.right = elDimensions.right }

        if(keys(newState).length > 0){
            this.setState(newState)
        }
    }

    render(){
        const {
            fixed,
            left,
            right
        } = this.state
    	const {
            screenSize,
    		children
    	} = this.props

    	return <React.Fragment>
            { fixed ? <div
                className="fixedWindowSticky"
                style={{
                    position: 'fixed',
                    zIndex: 5000,
                    left: left,
                    right: screenSize.width - right,
                    top: this.context.top
                }}>
                { React.Children.map(children, (_child) => {
                    return React.cloneElement(_child)
                }) }
                </div> : null }
            <div
                ref={ (el) => { this.el = el } }>
                { children }
            </div>
        </React.Fragment>
    }
}

Consumer.contextType = Context

const mapStateToProps = (state) => {
    return {
        screenSize: state.ui.screenSize
    }
}

export default connect(mapStateToProps, null)(Consumer)