import React 			    from 'react'
import classnames           from 'classnames'
import {
    isFinite,
    isFunction,
    times
} from 'lodash'

export default (props) => {
    const {
        activeIndex,
        onCrumbSelect,
        vertical,
        length,
        crumbContent,
        dark,
        noFade,
        className
    } = props

    return <div
        className={ classnames('breadcrumbs', {
            'breadcrumbs--dark': dark,
            'breadcrumbs--vertical': vertical,
            'breadcrumbs--haveContent': !!crumbContent,
            'breadcrumbs--noFade': noFade
        }, className) }>
        { isFinite(length) ? times(length, String).map((_o, _i) => {
            return <div
                key={ _i }
                className={ classnames('breadcrumb-item', {
                    'breadcrumb-item--active': activeIndex === _i
                }) }
                onClick={ (e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    if(onCrumbSelect && isFunction(onCrumbSelect)){
                        onCrumbSelect(_i)
                    }
                } }
                >
                { crumbContent && crumbContent[_i] ? <span>{ crumbContent[_i] }</span> : null }
            </div>
        }) : null }
    </div>
}