import { combineReducers } from 'redux'

import {
	HEADER_COLOUR_SET,
	HEADER_COLOUR_TRANSITION,
	HEADER_SET_HOVER,
	RESIZE_SCREEN
} from '../../actions'

import {
	screenSizeDefault
} from './defaults'

import breakpoints 		from '../../../lib/constants/breakpoints.json'

const colour = (state = 'light', action) =>{
	switch(action.type){
		case HEADER_COLOUR_SET:
			return action.colour
		default:
			return state
	}
}

const transitioningColour = (state = null, action) => {
	switch(action.type){
		case HEADER_COLOUR_TRANSITION:
			return {
				colour: action.colourTo,
				amount: action.amountPixels
			}
		case HEADER_COLOUR_SET:
			return null
		default:
			return state
	}
}

const headerHeightForScreenSize = (screenSize = screenSizeDefault) => {
	if(screenSize.width <= breakpoints.tablet){
		return 60
	} else {
		return 75
	}
}
const defaultHeaderHeight = headerHeightForScreenSize()

const height = (state = defaultHeaderHeight, action) => {
	switch(action.type){
		case RESIZE_SCREEN:
			return headerHeightForScreenSize(action.screenSize)
		default:
			return state
	}
}

const activeNavItem = (state = null, action) => {
	switch(action.type){
		case HEADER_SET_HOVER:
			return action.index
		default:
			return state
	}
}

export default combineReducers({
	colour,
	transitioningColour,
	height,
	activeNavItem
})