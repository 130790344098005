import React 				from 'react'
import requestFrame    		from 'request-frame'
import { connect } 			from 'react-redux'
import PS 					from 'publish-subscribe-js'
import iOSInnerHeight 		from 'ios-inner-height'

import { 
	resizeScreen,
	toggleMenu
} from '../../redux/actions'

import {
	RESIZE
} from '../../lib/pubSubEvents'

import breakpoints 	from '../../lib/constants/breakpoints.json'

class ResizeMonitor extends React.Component {

	constructor(props) {
		super(props)

		this.resizeHandler = this.resizeHandler.bind(this)
        this.checkResizeUpdate = this.checkResizeUpdate.bind(this)
	}

	componentDidMount() {
		window.addEventListener('resize', this.resizeHandler)
		this.resizeHandler()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeHandler)
		this.cancelResizeLoop()
	}

	cancelResizeLoop(){
		if(this.checkResizeUpdateLoopID){
			const cancel = requestFrame('cancel')
			cancel(this.checkResizeUpdateLoopID)
		}
	}

	resizeHandler() {
		this.cancelResizeLoop()

        const request = requestFrame('request')
        this.checkResizeUpdateLoopID = request(this.checkResizeUpdate)
	}

	clearResizeTO(){
		if(this.resizeTO){ clearTimeout(this.resizeTO) }
	}

	checkResizeUpdate(){
		const { 
			dispatch,
			overlayDetailsActive,
			menuOpen
		} = this.props

		const screenSize = {
			width: window.innerWidth,
			height: window.innerHeight,
			fullHeight: iOSInnerHeight()
		}

		if(menuOpen && screenSize.width > breakpoints.tabletPortrait){
			dispatch(toggleMenu(false))
		}

		dispatch(resizeScreen(screenSize))

		PS.publish(RESIZE, screenSize)
	}

	render(){
		return null
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state
	return {
		menuOpen: ui.menuOpen,
		screenSize: ui.screenSize
	}
}

export default connect(mapStateToProps)(ResizeMonitor)