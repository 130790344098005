import React from 'react'
import Measure from 'react-measure'
import classnames from 'classnames'
import Img from 'gatsby-image'

import preloadImages from '../../lib/preloadImages'
import { fitDimensionsToBox } from '../../lib/mathHelpers'

import Video from '../video'

class ARYImage extends React.Component {
  constructor(props) {
    super(props)

    this.handleImageLoaded = this.handleImageLoaded.bind(this)
    this.onMeasureChange = this.onMeasureChange.bind(this)
    this.imageLoadedPromise = null

    this.state = {
      imgWidth: null,
      imgHeight: null,
      imageLoaded: false,
    }
  }

  componentDidMount() {
    const { image } = this.props

    const src = this.imageSrcFromProps()

    if (src) {
      this.imageLoadedPromise = preloadImages([src])
      this.imageLoadedPromise.then(this.handleImageLoaded)
    } else {
      this.handleImageLoaded()
    }
  }

  componentWillUnmount() {
    if (this.imageLoadedPromise) {
      this.imageLoadedPromise.cancel()
    }
  }

  imageSrcFromProps() {
    const { image } = this.props

    if (!image) {
      return null
    } else {
      return image.svg ? image.svg : image.src
    }
  }

  onMeasureChange(dimensions) {
    const { contain, image } = this.props
    if(!image){
      return null
    }
    const { width, height, aspectRatio } = image

    const newDimensions = fitDimensionsToBox(
      {
        width: dimensions.client.width,
        height: dimensions.client.height,
      },
      {
        width: width,
        height: height,
        ratio: 1 / aspectRatio,
      },
      contain
    )

    this.setState({
      imgWidth: newDimensions.width,
      imgHeight: newDimensions.height,
    })
  }

  handleImageLoaded() {
    this.setState({
      imageLoaded: true,
    })
  }

  renderImage() {
    const { image, shouldPlay, videoOpts } = this.props
    if (!image) {
      return null
    }
    const {
      src,
      svg,
      videoSrc,
      width,
      height,
      gatsbyImage,
      gatsbyImageType,
      alt,
    } = image

    if (gatsbyImage) {
      return (
        <Img
          fixed={gatsbyImageType === 'fixed' ? image : null}
          fluid={gatsbyImageType === 'fluid' ? image : null}
        />
      )
    } else if (svg) {
      return <img src={svg} width={0} height={0} alt={alt} />
    } else if (videoSrc) {
      return (
        <Video
          poster={src}
          src={videoSrc}
          width={width}
          height={height}
          onVideoLoad={this.handleImageLoaded}
          shouldPlay={shouldPlay}
          videoOpts={videoOpts}
        />
      )
    } else {
      return <img src={src} width={width} height={height} alt={alt} />
    }
  }

  render() {
    const { imgWidth, imgHeight, imageLoaded } = this.state
    const { image, imageAlignment, className } = this.props

    if(!image){
      return null
    }

    const classes = classnames(
      'ary-imageBlock',
      {
        'ary-imageBlock--isLoaded': imageLoaded,
        [`ary-imageblock--align-${imageAlignment}`]: imageAlignment,
      },
      className
    )

    const imgStyles = {
      width: imgWidth,
      height: imgHeight,
      marginLeft: imgWidth * -0.5,
      marginTop: imgHeight * -0.5,
    }

    return (
      <Measure client onResize={this.onMeasureChange}>
        {({ measureRef }) => (
          <div ref={measureRef} className={classes}>
            <div className="ary-imageBlock-wrap" style={imgStyles}>
              {this.renderImage()}
            </div>
          </div>
        )}
      </Measure>
    )
  }
}

export default ARYImage
