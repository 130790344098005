import React 		from 'react'
import {
	Link
} from 'gatsby'

export default (props) => {
	const {
		to,
		external,
		renderA,
		children,
		className,
		...otherProps
	} = props

	if(to){
		return external || renderA ? <a href={ to } target={ external ? '_blank' : null } rel="noopener noreferrer" className={ className } { ...otherProps }>
			{ children }
		</a> : <Link to={ to } className={ className } { ...otherProps }>
			{ children }
		</Link>
	} else {
		return <div className={ className } { ...otherProps }>
			{ children }
		</div>
	}
}