import {
	toWords
} from 'number-to-words'
import {
	compact,
	get,
	find,
	filter,
	flatten,
	indexOf,
	orderBy,
} from 'lodash'

export const housesFromData = (data) => {
	const _houses = get(data, 'houses.edges')
	return _houses ? _houses.map((_house, _i) => {
		return _house.node
	}) : null
}

export const bedroomsFromData = (data, houses) => {
	const _bedrooms = get(data, 'bedrooms.edges')
	return _bedrooms ? orderBy(_bedrooms.filter((_bedroom, _i) => {
			return hasHouseWithBedrooms(_bedroom.node.wordpress_id, houses)
		}).map((_bedroom, _i) => {
			return {
				count: parseInt(_bedroom.node.name),
				..._bedroom.node
			}
		}),
		'count', 'asc'
	) : null
}

export const hasHouseWithBedrooms = (id, houses) => {
	const aHouse = find(houses, (_house, _i) => {
		return indexOf(_house.bedrooms, id) >= 0
	})
	return !!aHouse
}

export const housesForActiveBedroom = (id, houses) => {
	return filter(houses, (_house, _i) => {
		return indexOf(_house.bedrooms, id) >= 0
	})
}

export const bedroomsForActiveHouse = (bedroomIDs, bedrooms) => {
	return filter(bedrooms, (_bedroom, _i) => {
		return indexOf(bedroomIDs, _bedroom.wordpress_id) >= 0
	})
}

export const bedroomsAndOptions = (bedrooms, houses) => {
	return compact(flatten(bedrooms.map((_bedroom, _i) => {
		const _houses = housesForActiveBedroom(_bedroom.wordpress_id, houses)

		return _houses && _houses.length > 0 ? _houses.map((_house, _j) => {
			let _title = houseTitle(_house, bedrooms)

			if(_houses.length > 1){
				_title = `${ _title }, ${ optionLabelForIndex(_j, true) }`
			}

			return {
				bedroomIndex: _i,
				optionIndex: _j,
				label: _title
			}
		}) : null
	})))
}

export const houseTabs = [
	'elevations',
	'overview',
	'exteriors',
	'interiors',
	'floorplans',
	'prices'
]

export const labelForTab = (tab) => {
	switch(tab){
		case 'elevations':
			return 'Views'
		case 'exteriors':
			return 'Exterior'
		case 'interiors':
			return 'Interior'
		case 'floorplans':
			return 'Floor plans'
		default:
			return sentenceCase(tab)
	}
}

export const componentKeyForTab = (tab) => {
	switch(tab){
		case 'elevations':
			return 'Elevations'
		case 'exteriors':
			return 'Exteriors'
		case 'floorplans':
			return 'Floorplans'
		case 'specifications':
			return 'Specifications'
		case 'interiors':
			return 'Interiors'
		default:
			return null
	}
}

export const optionLabelForIndex = (index, short = false) => {
	const alphabet = 'abcdefghijklmnopqrstuvwxyz'
	const _index = Math.abs(index) % alphabet.length
	const letter = alphabet[_index].toUpperCase()
	return short ? letter : `Option ${ letter }`
}

export const houseTitle = (house, bedrooms) => {
	const bedroomID = house.bedrooms[0]
	const bedroom = find(bedrooms, {wordpress_id: bedroomID})
	return bedroomTitle(bedroom)
}

export const bedroomTitle = (bedroom = null) => {
	const count = bedroom.count
	return bedroom && isFinite(count) ? `${ sentenceCase(toWords(count)) } bedroom${ count > 1 ? 's' : null }` : null
}

export const sentenceCase = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getDetailMatrixHousesID = (details) => {
	if(!details){ return null }
	return get(find(details, (o) => {
		const _linkToFromHouses = get(o, 'node.acf.houses_matrix.link_to_houses_matrix')
		return _linkToFromHouses && _linkToFromHouses === true
	}), 'node.wordpress_id')
}

export const getDetailMatrixOptionsID = (details) => {
	if(!details){ return null }
	return get(find(details, (o) => {
		const _linkToFromHouses = get(o, 'node.acf.houses_matrix.link_to_options_matrix')
		return _linkToFromHouses && _linkToFromHouses === true
	}), 'node.wordpress_id')
}