import React 			from 'react'
import Measure			from 'react-measure'
import classnames		from 'classnames'
import {
	isFinite
} from 'lodash'

import ARYImage 		from '../ary-image'

import {
	fitDimensionsToBox
} from '../../lib/mathHelpers'

class InlineImage extends React.Component {
	constructor(props) {
		super(props)

		this.onMeasureChange 	= 	this.onMeasureChange.bind(this)

		this.state = {
			imgHeight: null
		}
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	onMeasureChange(dimensions) {
		const {
			width,
			height,
			aspectRatio
		} = this.props.image

		const newDimensions = fitDimensionsToBox(
			{
				width: dimensions.client.width,
				height: null
			},
			{
				width: width,
				height: height,
				ratio: 1 / aspectRatio
			},
			false
		)

		this.setState({
			imgHeight: newDimensions.height
		})
	}

	render() {
		const { className, ...propsToPassDown } = this.props
		const { imgHeight } = this.state

		if(!this.props.image){
			return null
		}

		const classes = classnames('inlineImage', className)

		return <Measure client onResize={ this.onMeasureChange }>
			{({ measureRef }) =>
				<div ref={ measureRef } className={ classes } style={{ height: imgHeight }}>
					<ARYImage { ...propsToPassDown } />
				</div>
			}
		</Measure>
	}

}

export default InlineImage