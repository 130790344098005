import {
	combineReducers
} from 'redux'

import ui 			from './ui'

const appState = combineReducers({
	ui
})

export default appState