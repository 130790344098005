import React 					from 'react'
import MailchimpSubscribe 		from 'react-mailchimp-subscribe'
import * as EmailValidator 		from 'email-validator'
import {
	isFunction
} from 'lodash'
import Cookies 					from 'js-cookie'

class NewsletterForm extends React.Component {
	constructor(props) {
		super(props)

		this.handleSubmit = this.handleSubmit.bind(this)

		this.state = {
			email: '',
			checked: null,
			error: null,
			sucess: false
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.status === 'success' && prevProps.status !== this.props.status){
			this.setSuccess()
		}
	}

	componentWillUnmount(){
		this.clearSuccessTO()
	}

	setSuccess(){
		this.setState({
			success: true,
			error: null
		})

		Cookies.set('kh_filled_newsletter', true, { expires: 30 })

		this.clearSuccessTO()
		this.successTO = setTimeout(() => {
			this.setState({
				email: '',
				checked: null,
				success: false
			})
		}, 2000)
	}

	clearSuccessTO(){
		if(this.successTO){ clearTimeout(this.successTO) }
	}

	handleSubmit(e){
		const {
			onSubmitted
		} = this.props
		const {
			email,
			checked
		} = this.state

		e.preventDefault()
		e.stopPropagation()

		let error = null
		if(!email){
			error = 'Please enter your email address'
		} else if(!EmailValidator.validate(email)){
			error = 'Please enter a valid email address'
		} else if(!checked){
			error = 'Please tick to confirm your subscription'
		}

		if(error){
			this.setState({
				error: error
			})
		} else {
			this.setState({
				error: null
			})
			if(onSubmitted && isFunction(onSubmitted)){
				onSubmitted({
					EMAIL: email
				})
			}
		}
	}

	render() {
		const {
			email,
			checked,
			error,
			success
		} = this.state
		const {
			status,
			message
		} = this.props

		const isSubmitting = status === 'sending'
		const hasError = error || (status === 'error' && message)
		const hasNote = isSubmitting || success

		return <form
			onSubmit={ this.handleSubmit }
			className="newsletter-form">
			{ hasError ? <div className="form-row form-row--error">
				{ error ? error : <div
					dangerouslySetInnerHTML={{__html: message}} /> }
			</div> : null }
			<div className="form-row">
				<input
					type="text"
					placeholder="Email address"
					value={ email }
					onChange={ (e) => {
						this.setState({
							email: e.target.value
						})
					} } />
			</div>
			<div className="form-row form-row--checkbox">
				<label
					htmlFor="newsletter-checkbox">
					Tick to confirm your newsletter subscription
				</label>
				<input
					type="checkbox"
					id="newsletter-checkbox"
					checked={ !!checked }
					onChange={ (e) => {
						this.setState({
							checked: e.target.checked
						})
					} } />
			</div>
			{ hasNote ? <div className="form-row form-row--note">
					{ isSubmitting ? 'Sending...' : 'Thank you, you are subscribed!' }
				</div> : <div className="form-row">
					<input
						type="submit"
						value="Subscribe" />
				</div> }
		</form>
	}
}

export default (props) => {
	return <div className="newsletter-inline">
		<MailchimpSubscribe
			url="https://kisshouse.us15.list-manage.com/subscribe/post?u=f9164c1cf75ff17764672a4b7&amp;id=810f58df50"
			render={ ({subscribe, status, message}) => {
				return <NewsletterForm
					status={ status }
					message={ message }
					onSubmitted={ (formData) => {
						subscribe(formData)
					} }
					/>
			} }
			/>
	</div>
}