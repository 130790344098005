import React 			from 'react'
import classnames		from 'classnames'

import LinkWrapper 		from '../link-wrapper'

export default (props) => {
	const {
		to,
		external,
		isDark,
		onClick,
		label,
		className,
		iconOnly,
		iconOnlyLarge,
		type
	} = props

	const _iconOnly = iconOnly || !label

	return <LinkWrapper
		to={ to }
		external={ external }
		onClick={ onClick ? onClick : null }
		className={ classnames('iconButton', {
			'iconButton--dark': !!isDark,
			'iconButton--iconOnly': _iconOnly,
			'iconButton--iconOnly-small': _iconOnly && !iconOnlyLarge,
			[`iconButton--type-${ type }`]: type
		}, className) }
		>
		<div className="iconButton-icon" />
		{ _iconOnly ? null : <span className="iconButton-text">{ label }</span> }
	</LinkWrapper>
}