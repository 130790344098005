import React 		from 'react'
import classnames 	from 'classnames'
import {
	get,
	isFinite
} from 'lodash'

import {
	FixedScrollWindowSticky
} from '../fixed-scroll-window'

import {
	renderHTML
} from '../../lib/_helpers'

const Cell = (props) => {
	const {
		c,
		index,
		isHighlighted,
		isNote
	} = props

	const isTick = c && c === 'y'

	return <div className={ classnames('table-cell', {
		'table-cell--positive': isTick,
		'table-cell--first': index === 0,
		'table-cell--isHighlighted': isHighlighted,
		'table-cell--isNote': isNote
	}) }>
		{ !isTick && c ? <span>{ renderHTML(c) }</span> : null }
	</div>
}

const Row = (props) => {
	const {
		className,
		cells,
		cellIndexHighlight,
		stack
	} = props

	const isSingular = cells && get(cells, '0.c') === '~'
	const _cells = isSingular ? [get(cells, '1')] : cells

	return <div className={ classnames('table-row', { 
			'table-row--stacked': stack
		}, className) }>
		{ _cells.map((_cell, _i) => {
			return <Cell
				key={ _i }
				index={ _i }
				isNote={ isSingular }
				isHighlighted={ _i === cellIndexHighlight }
				{ ..._cell } />
		}) }
	</div>
}

const Header = (props) => {
	const {
		cells,
		fixed,
		stack
	} = props

	const el = <Row
		className="table-row--header"
		stack={ stack }
		cells={ cells } />

	return fixed ? <FixedScrollWindowSticky>
		{ el }
	</FixedScrollWindowSticky> : el
}

export default (props) => {
	const {
		header,
		body,
		fixHeader,
		cellIndexHighlight,
		noted,
		style,
		stack,
		className
	} = props

	const cellCount = body && body.length > 0 ? body[0].length : null

	return <div
		style={ style }
		className={ classnames('table', {
			'table--narrow': isFinite(cellCount) && cellCount <= 5,
			'table--noted': noted
		}, className) }>
		{ header ? <Header
			stack={ stack }
			fixed={ fixHeader }
			cells={ header } /> : null }
		{ body ? body.map((_row, _i) => {
			return <Row
				key={ _i }
				stack={ stack }
				cells={ _row }
				cellIndexHighlight={ cellIndexHighlight } />
		}) : null }
	</div>
}