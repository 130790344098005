import React 			    from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'

import Breadcrumbs          from '../breadcrumbs'
import ImageGallery         from './gallery'
import NextPrev             from '../next-prev'

import {
	wrapIndexShift
} from '../../lib/_helpers'

class ImageGalleryWithBreadcrumbs extends React.Component {
	constructor(props) {
		super(props)

		this.onCrumbSelect = this.onCrumbSelect.bind(this)
		this.onNavDirection = this.onNavDirection.bind(this)

		this.state = {
			activeIndex: 0
		}
	}

	onCrumbSelect(index){
		this.setState({
			activeIndex: index
		})
	}

	onNavDirection(dir){
		const {
			activeIndex
		} = this.state
		const {
			images
		} = this.props

		this.setState({
			activeIndex: wrapIndexShift(activeIndex, dir, images.length)
		})
	}

	render() {
		const {
			activeIndex
		} = this.state
		const {
			images,
			contain,
			imageAlignment,
			swapSvg,
			containDifferentRatio
		} = this.props

		return <div
			className="imageGallery-wrapper">
			{ images && images.length > 0 ? <ImageGallery
				activeIndex={ activeIndex }
				contain={ contain }
				imageAlignment={ imageAlignment }
				containDifferentRatio={ containDifferentRatio }
				swapSvg={ swapSvg }
				images={ images }
				/> : null }
			{ images && images.length > 1 ? <NextPrev
				onNavDirection={ this.onNavDirection }
				/> : null }
			{ images && images.length > 1 ? <Breadcrumbs
				activeIndex={ activeIndex }
				onCrumbSelect={ this.onCrumbSelect }
				length={ images.length }
				/> : null }
		</div>
	}
}

export default ImageGalleryWithBreadcrumbs