import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import WebFont from 'webfontloader'
import Cookies from 'js-cookie'
import PS from 'publish-subscribe-js'

import '../lib/styles/index.styl'

import Cursor from '../components/cursor'
import HashRouteListener from '../components/hash-route-listener'
import Header from '../components/header'
import OverlayManager from '../components/overlay-manager'
import PageTransition from '../components/page-transition'
import { ResizeMonitor, ScrollMonitor } from '../components/ui-monitors'
import CookieNotice from '../components/cookie-notice'

import { HASH_CHANGE } from '../lib/pubSubEvents'

import { openOverlay, closeOverlay, toggleMenu } from '../redux/actions'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    if (WebFont) {
      WebFont.load({
        custom: {
          families: ['Canela Web'],
        },
        typekit: {
          id: 'tuk1jso',
        },
        active: () => {
          this.triggerLoad()
        },
        inactive: () => {
          this.triggerLoad()
        },
      })
    } else {
      this.triggerLoad()
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props

    if (prevProps.location.key !== this.props.location.key) {
      if (this.props.menuOpen) {
        dispatch(toggleMenu(false))
      }
      if (this.props.overlay) {
        dispatch(closeOverlay())
      }
    }
  }

  componentWillUnmount() {
    if (this.loadTO) {
      clearTimeout(this.loadTO)
    }
    if (this.newsletterTO) {
      clearTimeout(this.newsletterTO)
    }
  }

  triggerLoad() {
    const { dispatch, location } = this.props

    this.loadTO = setTimeout(() => {
      this.setState({
        loaded: true,
      })
    }, 10)

    const seenNewsletter = Cookies.get('kh_seen_newsletter')
    const filledNewsletter = Cookies.get('kh_filled_newsletter')
    if (!seenNewsletter && !filledNewsletter) {
      this.newsletterTO = setTimeout(() => {
        dispatch(openOverlay({ type: 'newsletter' }))
      }, 6000)
    }
  }

  render() {
    const { loaded } = this.state
    const {
      children,
      menuOpen,
      overlay,
      pageIntro,
      hasTouch,
      location,
      introAnimationDone,
      introAnimationOutPerc,
      cursor,
      hasCookie,
      dispatch,
    } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />

          <meta
            name="viewport"
            content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scaleable=no"
          />
          <meta name="msapplication-tap-highlight" content="no" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta name="apple-mobile-web-app-title" content="Kiss House" />

          <title>Kiss House</title>

          <body
            className={classnames({
              'site--hasTouch': !!hasTouch,
              'site--hasOverlay': !!menuOpen || !!overlay || !!pageIntro,
              'site--hasCursor': !!cursor,
              'site--loaded': loaded,
            })}
          />
        </Helmet>

        {!loaded ? <div className="khLoader" /> : null}

        {cursor ? <Cursor /> : null}

        <Header location={location} />

        <OverlayManager />

        <div className="pageContent">
          <PageTransition location={location}>{children}</PageTransition>
        </div>

        {hasCookie ? <CookieNotice /> : null}

        <ResizeMonitor />
        <ScrollMonitor />
        <HashRouteListener />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    hasCookie: ui.hasCookie,
    hasTouch: ui.hasTouch,
    menuOpen: ui.menuOpen,
    overlay: ui.overlay,
    pageIntro: ui.pageIntro,
    screenSize: ui.screenSize,
    cursor: ui.cursor,
  }
}

export default connect(mapStateToProps)(Layout)
