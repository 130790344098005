import React 		from 'react'
import classnames 	from 'classnames'
import {
	connect
} from 'react-redux'
import {
	TransitionGroup,
	CSSTransition,
	Transition
} from 'react-transition-group'
import {
	get
} from 'lodash'

import VideoOverlay 	from '../video-overlay'
import NewsletterOverlay 	from '../newsletter-overlay'
import TypeformForm 		from '../typeform-embed'
import DetailOverlay 	from '../detail-overlay'
import HousesOverlay 	from '../houses-overlay'
import PanelBg 			from '../panel-bg'
import ProjectGallery 	from '../project-gallery'
import SidePanel 		from '../side-panel'

import {
	formatImageArray,
	renderHTML
} from '../../lib/_helpers'

import {
	closeOverlay
} from '../../redux/actions'

import {
	overlayLayers,
	getLayerForOverlayType,
	getOverlayForLayer
} from './_helpers'

class OverlayManager extends React.Component {
	constructor(props) {
		super(props)
	}

	renderOverlay(overlay){
		const {
			dispatch
		} = this.props

		switch(overlay.type){
			case 'video':
				return <VideoOverlay
					image={ get(overlay, 'image') }
					/>
			case 'newsletter':
				return <NewsletterOverlay />
			case 'enquiry':
				return <SidePanel
					noPadding={ true }
					ignoreHeader={ true }
					panelSide="right"
					colour="offWhite"
					fullWidthMobile={ true }
					onClose={ () => {
						dispatch(closeOverlay())
					} }
					>
					<TypeformForm url="https://kisshouse.typeform.com/KHenquiry" />
				</SidePanel>
			case 'houses':
				const housesHouseChoice = get(overlay, 'id')
				const housesBedroomsChoice = get(overlay, 'bedroomID')
				
				return <HousesOverlay
					initialHouseChoice={ housesHouseChoice && isFinite(housesHouseChoice) ? housesHouseChoice : null }
					initialBedroomChoice={ housesBedroomsChoice && isFinite(housesBedroomsChoice) ? housesBedroomsChoice : null } />
			case 'detail':
				return <DetailOverlay
					detailID={ overlay.id } />
			case 'projectGallery':
				const projectTitle = get(overlay, 'data.title')
				const projectImages = formatImageArray(get(overlay, 'data.acf.gallery'), 'image')
				const projectType = get(overlay, 'data.acf.type')
				const projectYear = get(overlay, 'data.acf.date')
				const projectAwards = get(overlay, 'data.acf.awards')
				const projectPeople = get(overlay, 'data.acf.people')
				const projectDescription = get(overlay, 'data.acf.description')
				
				return <ProjectGallery
					panelColour={ overlay.colour }
					title={ projectTitle }
					images={ projectImages ? projectImages : [] }
					textContent={ <React.Fragment>
						{ projectType || projectYear ? <React.Fragment>
							<p><em>{ projectType }{ projectType && projectYear ? ', ' : null }{ projectYear }</em></p>
							<p className="p--seperator">—</p>
						</React.Fragment> : null }
						{ projectAwards && projectAwards.length > 0 ? <React.Fragment>
							<p><em>
								{ projectAwards.map((_award, _i) => {
									return <React.Fragment
										key={ _i }>
										{ renderHTML(_award.award) }
										{ _i < projectAwards.length - 1 ? <br /> : null }
									</React.Fragment>
								}) }
							</em></p>
							<p className="p--seperator">—</p>
						</React.Fragment> : null }
						{ projectPeople && projectPeople.length > 0 ? <React.Fragment>
							<p><em>
								{ projectPeople.map((_person, _i) => {
									return <React.Fragment
										key={ _i }>
										{ _person.person ? renderHTML(_person.person) : null }
										{ _person.person && _person.role ? <span> — </span> : null }
										{ _person.role ? renderHTML(_person.role) : null }
										{ _i < projectPeople.length - 1 ? <br /> : null }
									</React.Fragment>
								}) }
							</em></p>
							<p className="p--seperator">—</p>
						</React.Fragment> : null }
						{ projectDescription ? renderHTML(projectDescription) : null }
					</React.Fragment> }
					/>
			case 'teamMember':
				const teamMemberTitle = get(overlay, 'data.title')
				const teamMemberPosition = get(overlay, 'data.acf.position')
				const teamMemberDescription = get(overlay, 'data.acf.description')

				return <SidePanel
					ignoreHeader={ true }
					panelSide={ overlay.panelSide }
					colour={ overlay.colour }
					title={ teamMemberTitle }
					subtitle={ null }
					textContentClassName="textBlock--mid"
					textContent={ teamMemberDescription ? renderHTML(teamMemberDescription) : null }
					onClose={ () => {
						dispatch(closeOverlay())
					} } />
			default:
				return null
		}
	}

	render() {
		const {
			overlay,
			screenSize,
			dispatch
		} = this.props

		return <React.Fragment>
            { overlayLayers.map((_layer, _i) => {
            	const _layerOverlay = getOverlayForLayer(overlay, _layer)
            	const _layerOverlayEl = !!_layerOverlay ? this.renderOverlay(_layerOverlay) : null

            	return <React.Fragment 
	            	key={ _i }>
            		{ _layerOverlayEl ? <PanelBg
		                className={ classnames('overlay-bgEl', `overlay-bgEl--${ _layer }`) }
		                onClick={ () => {
		                	dispatch(closeOverlay(_layer))
		                } } /> : null }
	            	<TransitionGroup
	            		component={ null }>
						{ !!_layerOverlayEl ? <CSSTransition
		      				key={ overlay.type }
		      				classNames='oT-'
		      				timeout={{
		      					enter: 900,
		 						exit: 600
		 					}}>
		 					{ _layerOverlayEl }
						</CSSTransition> : null }
					</TransitionGroup>
				</React.Fragment>
            }) }
		</React.Fragment>
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

    return {
    	overlay: ui.overlay,
    	screenSize: ui.screenSize
    }
}

export default connect(mapStateToProps, null)(OverlayManager)
