import React from 'react'
import { Provider } from 'react-redux'
import PS from 'publish-subscribe-js'

import { configureStore } from './src/redux'

import { linkSameMinusHash } from './src/lib/formatLink'
import { scrollToID, scrollToY } from './src/lib/scrollTo'
import { HASH_CHANGE } from './src/lib/pubSubEvents'

export const wrapRootElement = ({ element }) => {
  const store = configureStore()

  return <Provider store={store}>{element}</Provider>
}

const transitionDelay = 600
let previousPath = window ? window.location.href : null

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    if (previousPath && linkSameMinusHash(previousPath, location.href)) {
      PS.publish(HASH_CHANGE, {
        location,
        animate: true,
      })
    } else {
      window.setTimeout(() => {
        PS.publish(HASH_CHANGE, {
          location,
          animate: false,
        })
      }, transitionDelay)
    }
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => {
      scrollToY(savedPosition ? savedPosition[0] : 0, 0)
    }, transitionDelay)
  }
  previousPath = location.href
  return false
}
