import React 		from 'react'
import classnames 	from 'classnames'
import { connect } 	from 'react-redux'
import {
	StaticQuery,
	graphql,
	Link
} from 'gatsby'
import {
	findIndex,
	isFinite,
	omit
} from 'lodash'
import PS 					from 'publish-subscribe-js'

import HeaderNav, {
	HeaderLink,
	HeaderNavLinks
} from '../header-nav'

import { 
	toggleMenu,
	setHeaderHover
} from '../../redux/actions'

import breakpoints 	from '../../lib/constants/breakpoints.json'
import {
	formatLink
} from '../../lib/formatLink'

import {
	housesFromData,
	bedroomsFromData,
	bedroomTitle
} from '../houses-overlay/_helpers'

import {
	openOverlay
} from '../../redux/actions'

import {
	RESIZE,
	SCROLL
} from '../../lib/pubSubEvents'

class Header extends React.Component {
	constructor(props) {
		super(props)

		this.onMouseEnterSubNav = this.onMouseEnterSubNav.bind(this)
		this.onMouseLeaveSubNav = this.onMouseLeaveSubNav.bind(this)
		this.onMouseEnterNavItem = this.onMouseEnterNavItem.bind(this)
		this.onMouseLeaveNavItem = this.onMouseLeaveNavItem.bind(this)

		this.mouseInTOs = {}

		this.state = {
			menuLinkOpen: null
		}
	}

	componentWillUnmount() {
		this.clearAllMouseTOs()
	}

	componentDidUpdate(prevProps){
		if(
			(prevProps.location.pathname !== this.props.location.pathname)
		){
			this.setSubNav(null)
		}

		if(prevProps.menuOpen !== this.props.menuOpen && isFinite(this.state.menuLinkOpen)){
			this.setState({
				menuLinkOpen: null
			})
		}
	}

	setSubNav(index){
		const {
			dispatch
		} = this.props

		dispatch(setHeaderHover(index))
	}

	onMouseEnterSubNav(){
		this.clearMouseOutTO()
	}

	onMouseLeaveSubNav(){
		this.clearMouseOutTO()
		this.setMouseOutTO(100)
	}

	onMouseEnterNavItem(index){
		const {
			header
		} = this.props

		this.clearMouseOutTO()
		let time = 10
		if(isFinite(header.activeNavItem)){
			time = 150
		}

		this.mouseInTOs['index' + index] = setTimeout(() => {
			this.setSubNav(index)
		}, time)
	}

	onMouseLeaveNavItem(index){
		this.clearMouseInTO(index)
		this.clearMouseOutTO()
		this.setMouseOutTO()
	}

	setMouseOutTO(time = 250){
		this.clearMouseOutTO()
		this.mouseOutTO = setTimeout(() => {
			this.setSubNav(null)
		}, time)
	}

	clearMouseOutTO(){
		if(this.mouseOutTO){ clearTimeout(this.mouseOutTO) }
	}

	clearMouseInTO(index){
		const _key = 'index' + index
		if(this.mouseInTOs[_key]){
			clearTimeout(this.mouseInTOs[_key])
			this.mouseInTOs = omit(this.mouseInTOs, _key)
		}
	}

	clearAllMouseTOs(){
		const {
			links
		} = this.props

		this.clearMouseOutTO()
		if(links){
			for(let i = 0; i < links.length; i++){
				this.clearMouseInTO(i)
			}
		}
	}

	render() {
		const {
			menuLinkOpen
		} = this.state
		const {
			data,
			dispatch,
			menuOpen,
			screenSize,
			header,
			pageIntro,
			location,
			links
		} = this.props
		const {
			colour,
			transitioningColour
		} = header

		let activeHeaderNavs = []

		if(transitioningColour && transitioningColour.colour !== colour && transitioningColour.amount > 0){
			if(transitioningColour.colour === 'dark' || colour === 'dark'){
				activeHeaderNavs.push({
					transitioning: true,
					amount: transitioningColour.amount,
					fromColour: colour,
					colour: 'light'
				})
				activeHeaderNavs.push({
					colour: 'dark'
				})
			} else {
				if(transitioningColour.amount > 0){
					activeHeaderNavs.push({
						transitioning: true,
						amount: transitioningColour.amount,
						fromColour: colour,
						colour: transitioningColour.colour
					})
				}
				activeHeaderNavs.push({
					colour: colour
				})
			}
		} else {
			activeHeaderNavs.push({
				colour: colour
			})
		}

		const mobileNav = screenSize.width <= breakpoints.tabletPortrait

		return <React.Fragment>
			<header
				className={ classnames({
					'header--menuOpen': menuOpen,
					'header--mobile': mobileNav
				}) }>
				{ activeHeaderNavs.length > 0 ? activeHeaderNavs.map((_activeHeaderNav, _i) => {
					return <HeaderNav
						key={ _activeHeaderNav.colour }
						className={ classnames({
							'headerNav--transitioning': _activeHeaderNav.transitioning
						}) }
						colour={ _activeHeaderNav.colour }
						isTransitioning={ _activeHeaderNav.transitioning }
						amountTransitioned={ _activeHeaderNav.amount }
						transitioningFromColour={ _activeHeaderNav.fromColour }
						onMouseEnterSubNav={ this.onMouseEnterSubNav }
						onMouseLeaveSubNav={ this.onMouseLeaveSubNav }
						onMouseEnterNavItem={ this.onMouseEnterNavItem }
						onMouseLeaveNavItem={ this.onMouseLeaveNavItem }
						location={ location }
						links={ links }
						mobileNav={ mobileNav } />
				}) : null }
			</header>
			<div className={ classnames('header-menu', {
				'header-menu--menuOpen': menuOpen
			}) }>
				<HeaderNav
					colour="light"
					mobileNav={ mobileNav } />
				<div className="header-menu-links">
					{ links && links.length > 0 ? [...links,
							{
								title: 'Make an enquiry',
								link: '#',
								action: (dispatch) => {
									dispatch(openOverlay({type: 'enquiry'}))
								}
							}
						].map((_link, _i) => {
							const _hasSubLinks = _link.subLinks && _link.subLinks.length > 0
							const _subLinkOpen = menuLinkOpen === _i

							return <div
								className={ classnames('header-menu-link-set', {
									'header-menu-link-set--open': _subLinkOpen
								}) }
								key={ _i }>
								<HeaderLink
									elType="div"
									dispatch={ dispatch }
									className="header-menu-link"
									onClick={ _hasSubLinks && !_subLinkOpen ? (e) => {
										e.preventDefault()
										e.stopPropagation()

										this.setState({
											menuLinkOpen: _i
										})
									} : null }
									{ ..._link }
									/>
								{ _hasSubLinks ? <div className="header-menu-link-set header-menu-link-set--subLinks">
										{ _link.subLinks.map((__link, _j) => {
											return <HeaderLink
												key={ `${ _i }-${ _j }` }
												elType="div"
												dispatch={ dispatch }
												className="header-menu-link"
												{ ...__link }
												/>
										}) }
									</div> : null }
							</div>
						}) : null }
				</div>
			</div>
		</React.Fragment>
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

    return {
    	header: ui.header,
    	menuOpen: ui.menuOpen,
    	screenSize: ui.screenSize,
    	pageIntro: ui.pageIntro
    }
}

const ReduxHeader = connect(mapStateToProps, null)(Header)

export default (props) => {
	return <StaticQuery
		query={ graphql`
			query {
				houses: allWordpressWpHouse {
					edges {
						node {
							wordpress_id
							title
							bedrooms
						}
					}
				}
				bedrooms: allWordpressWpBedrooms {
					edges {
						node {
							name
							wordpress_id
						}
					}
				}
			}
		` }
		render={ (data) => {
			const houses = housesFromData(data)
			const bedrooms = bedroomsFromData(data, houses)

			const bedroomLinks = bedrooms ? bedrooms.map((_bedroom, _i) => {
				return {
					title: bedroomTitle(_bedroom),
					link: '/houses/',
					action: (dispatch) => {
						dispatch(openOverlay({
							type: 'houses',
							bedroomID: _bedroom.wordpress_id
						}))
					}
				}
			}) : []

			const links = [
				{
					title: 'Houses',
					link: '/houses/',
					subLinks: [
						{
							title: 'Why Kiss',
							link: '/houses/'
						},
						...bedroomLinks
					]
				},
				{
					title: 'About us',
					link: '/about-us/',
					subLinks: [
						{
							title: 'Meet the team',
							link: '/about-us/#team'
						},
						{
							title: 'Past projects',
							link: '/about-us/#past-projects'
						}
					]
				},
				{
					title: 'Info',
					link: '/info/',
					subLinks: [
						{
							title: 'Your journey',
							link: '/info/#journey'
						},
						{
							title: 'FAQs',
							link: '/info/#faqs'
						},
						{
							title: 'The details',
							link: '/info/#the-details'
						}
					]
				},
				{
					title: 'Stories',
					link: '/stories/'
				},
				{
					title: 'Contact',
					link: '/contact/'
				}
			]

			return <ReduxHeader
				links={ links }
				{ ...props } />
		} } />
}