// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page-about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-homepage-js": () => import("./../../../src/templates/page-homepage.js" /* webpackChunkName: "component---src-templates-page-homepage-js" */),
  "component---src-templates-page-houses-js": () => import("./../../../src/templates/page-houses.js" /* webpackChunkName: "component---src-templates-page-houses-js" */),
  "component---src-templates-page-info-js": () => import("./../../../src/templates/page-info.js" /* webpackChunkName: "component---src-templates-page-info-js" */),
  "component---src-templates-page-stories-js": () => import("./../../../src/templates/page-stories.js" /* webpackChunkName: "component---src-templates-page-stories-js" */),
  "component---src-templates-page-text-js": () => import("./../../../src/templates/page-text.js" /* webpackChunkName: "component---src-templates-page-text-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

