import React 			from 'react'
import videojs 			from 'video.js'
import {
	isUndefined
} from 'lodash'

class Video extends React.Component {
	
	constructor(props) {
		super(props)

		this.onPlayerReady = this.onPlayerReady.bind(this)
	}

	componentDidMount() {
		this.player = videojs(this.videoNode, {}, this.onPlayerReady)
	}

	componentWillUnmount() {
		if(this.player){
			this.player.dispose()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(
			prevProps.scrollInView != this.props.scrollInView
			|| prevProps.shouldPlay != this.props.shouldPlay
		){
			this.shouldPlayToggle()
		}
	}

	shouldPlayToggle() {
		if(this.player){
			if(this.props.scrollInView || this.props.shouldPlay){
				this.player.play()
			} else {
				this.player.pause()
			}
		}
	}

	onPlayerReady() {
		this.props.onVideoLoad()
		this.shouldPlayToggle()
	}

	render() {
		const {
			poster,
			width,
			height,
			src,
			shouldPlay,
			videoOpts
		} = this.props

		return (
			<div 
				className="videojsWrapper"
				data-vjs-player>
				<video
					ref={(el) => this.videoNode = el }
					className="video-js"
					poster={ poster }
					width={ width }
					height={ height }
					muted={ !isUndefined(videoOpts) && videoOpts && !isUndefined(videoOpts.muted) ? videoOpts.muted: true }
					loop={ !isUndefined(videoOpts) && videoOpts && !isUndefined(videoOpts.loop) ? videoOpts.loop : true }
					autoPlay={ !isUndefined(videoOpts) && videoOpts && !isUndefined(videoOpts.autoplay) ? videoOpts.autoplay : true }
					playsInline
					preload="metadata"
					>
					{ 
						src.map((_src, _i) => {
							return <source key={ _i } src={ _src.src } type={ _src.type } />
						})
					}
				</video>
			</div>
		)
	}
	
}

export default Video