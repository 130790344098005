import React 		from 'react'
import {
	connect
} from 'react-redux'
import {
	StaticQuery,
	graphql
} from 'gatsby'
import {
	get,
	find
} from 'lodash'

import CloseButton 	from '../close-button'
import IconButton 	from '../icon-button'
import StoryText 	from '../story/text'
import Table 		from '../table'
import {
	FixedScrollWindowContainer
} from '../fixed-scroll-window'

import breakpoints 	from '../../lib/constants/breakpoints.json'
import {
	formatTitle,
	renderHTML
} from '../../lib/_helpers'
import {
	FileFragment
} from '../../lib/fragments'

import {
	closeOverlay
} from '../../redux/actions'

const toTableCell = (cell) => {
	return {c : cell}
}

class DetailOverlay extends React.Component {
	constructor(props) {
		super(props)

		this.onCloseHandler = this.onCloseHandler.bind(this)
    }

    onCloseHandler(e){
    	const {
    		dispatch
    	} = this.props

    	e.stopPropagation()
		e.preventDefault()

		dispatch(closeOverlay('detail'))
    }

    renderContent(type){
    	const {
    		screenSize,
    		detail
    	} = this.props

    	const stackTable = screenSize.width <= breakpoints.mobilePortrait

    	switch(type){
    		case 'matrix':
    			const matrix = get(detail, 'acf.houses_matrix.matrix')
    			return <Table
					header={ matrix.header }
					fixHeader={ true }
					body={ matrix.body }
					stack={ stackTable } />
    		case 'comparison':
    			const comparisonIntroduction = get(detail, 'acf.houses_comparison.introduction')
    			const comparisonTableRows = get(detail, 'acf.houses_comparison.rows')
    			let comparisonTableBody = []
    			if(comparisonTableRows){
    				for(let i = 0; i < comparisonTableRows.length; i++){
    					const _row = comparisonTableRows[i]
    					comparisonTableBody.push([
    						toTableCell(_row.label),
    						toTableCell(_row.deerings),
    						toTableCell(_row.kiss),
    						toTableCell(_row.sandpath)
    					])
    					if(_row.note){
    						comparisonTableBody.push([
    							toTableCell('~'),
    							toTableCell(_row.note)
    						])
    					}
    				}
    			}

    			return <React.Fragment>
    				{ comparisonIntroduction ? <div className="textBlock detailOverlay-introduction">
    					{ renderHTML(comparisonIntroduction) }
    				</div> : null }
    				{ comparisonTableBody.length > 0 ? <Table
	    				header={ [
	    					null,
	    					toTableCell('The Deerings'),
	    					toTableCell('Kiss House'),
	    					toTableCell('Sandpath')
	    				] }
	    				body={ comparisonTableBody }
	    				cellIndexHighlight={ 2 }
	    				noted={ true }
	    				stack={ stackTable }
	    				/> : null }
	    		</React.Fragment>
    		case 'text':
    			const textRows = get(detail, 'acf.houses_text_detail')
    			const textFooter = get(detail, 'acf.houses_text_footer')

    			return <StoryText
    				rows={ textRows }
    				footer={ textFooter } />
    		default:
    			return null
    	}
    }

    containerHasFixedEls(type){
    	switch(type){
    		case 'matrix':
    		case 'comparison':
    			return true
    		default:
    			return false
    	}
    }

	render() {
		const {
			detail
		} = this.props

		if(!detail){ return null }

		const fileDownload = get(detail, 'acf.download.source_url')
		const type = get(detail, 'acf.type')

		const ContentContainer = this.containerHasFixedEls(type) ? FixedScrollWindowContainer : `div`
		const contentEl = <div className="detailOverlay-sheet-content-inner">
			{ this.renderContent(detail.acf.type) }
		</div>

		return <div className="detailOverlay overlay"
			onClick={ this.onCloseHandler }
			>
			<div className="detailOverlay-sheet"
				onClick={ (e) => {
					e.stopPropagation()
				} }>
				{ detail.title ? <div className="detailOverlay-sheet-title">
					<div className="textBlock textBlock--center">
						<h3>{ formatTitle(detail.title) }</h3>
					</div>
				</div> : null }
				<ContentContainer className="detailOverlay-sheet-content">
					{ contentEl }
				</ContentContainer>
				<div className="detailOverlay-sheet-footer">
					<CloseButton
						onClick={ this.onCloseHandler } />
					{ fileDownload ? <IconButton
						to={ fileDownload }
						external={ true }
						type="download"
						label="Download"
						/> : null }
				</div>
			</div>
		</div>
	}
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.ui.screenSize
    }
}

const ReduxDetailOverlay = connect(mapStateToProps, null)(DetailOverlay)

export default (props) => {
	return <StaticQuery
		query={ graphql`
			query {
				details: allWordpressWpDetail {
					edges {
						node {
							wordpress_id
							title
							acf {
                                download {
                                    ...FileWPMedia
                                }
								type
								houses_matrix {
									matrix {
										header {
											c
										}
										body {
											c
										}
									}
								}
								houses_comparison {
									introduction
									rows {
										label
										deerings
										kiss
										sandpath
										note
									}
								}
								houses_text_detail {
									__typename
									... on WordPressAcf_text {
										text
									}
									... on WordPressAcf_quote {
										quote
										from
									}
									... on WordPressAcf_subheading {
										title
										display_secondary
									}
								}
								houses_text_footer
							}
						}
					}
				}
			}
		` }
		render={ (data) => {
			const details = get(data, 'details.edges')
			const detail = details ? find(details, (o) => {
				return o.node.wordpress_id === props.detailID
			}) : null

			return <ReduxDetailOverlay
				data={ data }
				details={ details }
				detail={ detail ? detail.node : null }
				{ ...props } />
		} } />
}