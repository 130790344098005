import React 				from 'react'
import requestFrame    		from 'request-frame'
import { connect } 			from 'react-redux'
import PS 					from 'publish-subscribe-js'

import {
	SCROLL
} from '../../lib/pubSubEvents'

import breakpoints 	from '../../lib/constants/breakpoints.json'

class ScrollMonitor extends React.Component {

	constructor(props) {
		super(props)
		
		this.scrollHandler = this.scrollHandler.bind(this)
        this.checkScrollUpdate = this.checkScrollUpdate.bind(this)

        this.scrollPos = 0
	}

	componentDidMount() {
		window.addEventListener('scroll', this.scrollHandler)
		this.scrollHandler()
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollHandler)
		this.clearScrollTO()

		this.cancelScrollLoop()
	}

	cancelScrollLoop(){
		if(this.checkScrollUpdateLoopID){
			const cancel = requestFrame('cancel')
			cancel(this.checkScrollUpdateLoopID)
		}
	}

	scrollHandler() {
		this.cancelScrollLoop()
		
        const request = requestFrame('request')
        this.checkScrollUpdateLoopID = request(this.checkScrollUpdate)
	}

	clearScrollTO() {
		if(this.scrollTO){ clearTimeout(this.scrollTO) }
	}

	checkScrollUpdate(){
		const {
			headerHeight,
			headerIsScrolled,
			dispatch,
			screenSize
		} = this.props
		
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop

		PS.publish(SCROLL, scrollTop)

		this.clearScrollTO()
		this.scrollTO = setTimeout(() => {
			this.scrollPos = scrollTop
		}, 500)
	}

	render() {
		return null
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state
	return {
		screenSize: ui.screenSize
	}
}

export default connect(mapStateToProps)(ScrollMonitor)