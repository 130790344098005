import React                from 'react'
import classnames           from 'classnames'
import {
	get
} from 'lodash'
import {
	toWords
} from 'number-to-words'

import {
	ImageGallery
} from '../../image-gallery'
import Toggle	 			from '../../toggle'

import Panel				from '../panel'
import {
	GalleryTab,
	SplitTab
} from '../tab-components'

import {
	formatImageArray,
	renderHTML
} from '../../../lib/_helpers'

class Interiors extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			activeIndex: 0
		}
	}

	render() {
		const {
			activeIndex
		} = this.state
		const {
			houseOptions,
			title,
			subtitle
		} = this.props

		const interiors = get(houseOptions, 'interiors')
		let _title = title
		if(subtitle){
			_title += ` (${ subtitle })`
		}
		_title += ' interior'

		return <React.Fragment>
			<div className="housesOverlay-row housesOverlay-row--stretch housesOverlay-tabContent-content">
				{ interiors ? <SplitTab
					title={ _title }
					textContent={ interiors.description }
					footerContent={ interiors.image_credits }
					images={ interiors.images ? formatImageArray(interiors.images, 'image') : null }
					/> : null }
			</div>
		</React.Fragment>
	}
}

export default Interiors