import React                from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import {
    isFunction
} from 'lodash'

class PanelBg extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            onClick,
            className
        } = this.props

        return <div
        	className={ classnames('panelBg', className) }
            onClick={ (e) => {
                e.preventDefault()
                e.stopPropagation()

                if(onClick && isFunction(onClick)){
                    onClick()
                }
            } } />
    }
}

export default PanelBg