import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PS from 'publish-subscribe-js'
import { CSSTransition } from 'react-transition-group'

import Breadcrumbs from '../breadcrumbs'
import CloseButton from '../close-button'
import IconButton from '../icon-button'
import { ImageGallery } from '../image-gallery'
import PanelBg from '../panel-bg'
import NextPrev from '../next-prev'
import SidePanel from '../side-panel'

import breakpoints from '../../lib/constants/breakpoints.json'
import { bgColourIsDark, wrapIndexShift } from '../../lib/_helpers'
import { SCROLL } from '../../lib/pubSubEvents'

import { closeOverlay, setHeaderColour } from '../../redux/actions'

class ProjectGallery extends React.Component {
  constructor(props) {
    super(props)

    this.keydownHandler = this.keydownHandler.bind(this)
    this.onCrumbSelect = this.onCrumbSelect.bind(this)
    this.onNavDirection = this.onNavDirection.bind(this)
    this.closePanel = this.closePanel.bind(this)

    this.state = {
      activeIndex: 0,
      detailVisible: false,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props

    //dispatch(setHeaderColour('dark'))
    document.addEventListener('keydown', this.keydownHandler)
  }

  componentWillUnmount() {
    //PS.publish(SCROLL, null)
    document.removeEventListener('keydown', this.keydownHandler)
  }

  onCrumbSelect(index) {
    this.setState({
      activeIndex: index,
    })
  }

  onNavDirection(dir) {
    this.navDirectionIndex(dir)
  }

  keydownHandler(e) {
    switch (e.keyCode) {
      case 37:
      case 38:
        this.navDirection('prev')
        break
      case 39:
      case 40:
        this.navDirection('next')
        break
      default:
        return null
    }
  }

  navDirection(dir) {
    const toAdd = dir === 'prev' ? -1 : 1
    this.navDirectionIndex(toAdd)
  }

  navDirectionIndex(dir) {
    const { activeIndex } = this.state
    const { images } = this.props

    this.setState({
      activeIndex: wrapIndexShift(activeIndex, dir, images.length),
    })
  }

  closePanel() {
    this.setState({
      detailVisible: false,
    })
  }

  render() {
    const { activeIndex, detailVisible } = this.state
    const {
      title,
      textContent,
      images,
      screenSize,
      panelColour,
      dispatch,
    } = this.props

    const isMobile = screenSize.width <= breakpoints.tabletPortrait
    const isMobilePortrait = screenSize.width <= breakpoints.mobilePortrait

    const hasImages = images && images.length > 0

    return (
      <div
        className={classnames('projectGallery', 'overlay')}
        style={{
          width: screenSize.width,
          height: screenSize.height,
        }}
      >
        <CSSTransition
          in={detailVisible}
          unmountOnExit={true}
          classNames="oT-"
          timeout={{
            enter: 900,
            exit: 600,
          }}
        >
          <SidePanel
            ignoreHeader={true}
            panelSide={'right'}
            colour={panelColour ? panelColour : 'orangeDark'}
            title={title}
            textContent={textContent}
            onClose={this.closePanel}
          />
        </CSSTransition>
        {detailVisible ? <PanelBg onClick={this.closePanel} /> : null}
        {images ? (
          <ImageGallery
            activeIndex={activeIndex}
            images={images}
            containDifferentRatio={true}
          />
        ) : null}
        {images && images.length > 0 ? (
          <NextPrev onNavDirection={this.onNavDirection} />
        ) : null}
        <div
          className={classnames('projectGallery-footer', {
            'projectGallery-footer--mobile': isMobile,
          })}
        >
          <div className="cont">
            <div
              className="projectGallery-footer-close"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()

                dispatch(closeOverlay())
              }}
            >
              <CloseButton isDark={true} />
            </div>
            <div
              className="projectGallery-footer-moreInfo"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()

                this.setState({
                  detailVisible: true,
                })
              }}
            >
              <span>
                {isMobile
                  ? `About${!isMobilePortrait ? ' this project' : ''}`
                  : 'Read about this project'}
              </span>
            </div>
            {isMobile && hasImages ? (
              <IconButton
                type="next"
                isDark={true}
                className="projectGallery-footer-arrow"
                iconOnlyLarge={true}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()

                  this.navDirection('next')
                }}
              />
            ) : (
              <div className="projectGallery-footer-breadcrumbs">
                {hasImages ? (
                  <Breadcrumbs
                    activeIndex={activeIndex}
                    onCrumbSelect={this.onCrumbSelect}
                    length={images.length}
                    className="breadcrumbs--softer"
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(
  mapStateToProps,
  null
)(ProjectGallery)
