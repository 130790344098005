import React from 'react'
import { connect } from 'react-redux'
import PS from 'publish-subscribe-js'
import { get, isUndefined } from 'lodash'
import { graphql, StaticQuery } from 'gatsby'

import {
  getDetailMatrixHousesID,
  getDetailMatrixOptionsID,
} from '../houses-overlay/_helpers'

import {
  linkHasHash,
  hashPartsFromLink,
  isOverlayHash,
  overlayDispatchObjFromHash,
} from '../../lib/formatLink'
import { scrollToY, scrollToID } from '../../lib/scrollTo'
import { HASH_CHANGE } from '../../lib/pubSubEvents'

import { openOverlay } from '../../redux/actions'

class HashRouteListener extends React.Component {
  constructor(props) {
    super(props)

    this.prevLocation = null

    this.onHashChange = this.onHashChange.bind(this)
  }

  componentDidMount() {
    this.hashKey = PS.subscribe(HASH_CHANGE, this.onHashChange)

    this.onHashChange()
  }

  componentWillUnmount() {
    if (this.hashKey) {
      PS.unsubscribe(HASH_CHANGE, this.hashKey)
    }
  }

  onHashChange(props = null) {
    const { dispatch } = this.props

    const location =
      props && props.href
        ? props.href
        : props && props.location
        ? props.location.href
        : window
        ? window.location.href
        : null
    const animate = props && !isUndefined(props.animate) ? props.animate : false
    const toTop = props && !isUndefined(props.toTop) ? props.toTop : false
    const time = animate ? 1200 : 0

    const hasHash = linkHasHash(location)

    if (hasHash) {
      const hashParts = hashPartsFromLink(location)
      if (isOverlayHash(hashParts)) {
        dispatch(openOverlay(overlayDispatchObjFromHash(hashParts, this.props)))
      } else {
        scrollToID(hashParts[0], time)
      }
    } else {
      scrollToY(0, time)
    }
  }

  render() {
    return null
  }
}

export const withLinkQuery = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              details: allWordpressWpDetail {
                edges {
                  node {
                    wordpress_id
                    slug
                    path
                    acf {
                      houses_matrix {
                        link_to_houses_matrix
                        link_to_options_matrix
                      }
                    }
                  }
                }
              }
              houses: allWordpressWpHouse {
                edges {
                  node {
                    wordpress_id
                    slug
                    path
                  }
                }
              }
            }
          `}
          render={(data) => {
            const details = get(data, 'details.edges')
            const houses = get(data, 'houses.edges')
            const detailMatrixHousesID = getDetailMatrixHousesID(details)
            const detailMatrixOptionsID = getDetailMatrixOptionsID(details)

            return (
              <WrappedComponent
                detailMatrixHousesID={detailMatrixHousesID}
                detailMatrixOptionsID={detailMatrixOptionsID}
                details={details.map((_detail, _i) => {
                  return _detail.node
                })}
                houses={houses.map((_house, _i) => {
                  return _house.node
                })}
                {...this.props}
              />
            )
          }}
        />
      )
    }
  }
}

export default withLinkQuery(connect()(HashRouteListener))
