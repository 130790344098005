import React 			from 'react'
import { connect } 		from 'react-redux'
import {
	get,
	includes,
	isFinite,
	isFunction,
	isString
} from 'lodash'
import {
	graphql,
	StaticQuery
} from 'gatsby'

import LinkWrapper 		from '../link-wrapper'
import {
	withLinkQuery
} from '../hash-route-listener'

import {
	openOverlay
} from '../../redux/actions'

const InlineLink = connect()((props) => {
	const {
		dispatch,
		onClick,
		internalTo,
		href,
		children
	} = props

	const hasClick = onClick && isFunction(onClick)

	return <LinkWrapper
		to={ internalTo ? internalTo : href }
		renderA={ hasClick }
		onClick={ hasClick ? (e) => {
			e.preventDefault()
			e.stopPropagation()

			onClick(props)
		} : null }>
		{ children }
	</LinkWrapper>
})

export default withLinkQuery(InlineLink)