import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'

import CloseButton from '../close-button'
import Newsletter from '../newsletter'

import { closeOverlay } from '../../redux/actions'

class NewsletterOverlay extends React.Component {
  constructor(props) {
    super(props)

    this.closeOverlayHandler = this.closeOverlayHandler.bind(this)
  }

  closeOverlayHandler(e) {
    const { dispatch } = this.props

    e.preventDefault()
    e.stopPropagation()

    Cookies.set('kh_seen_newsletter', true, { expires: 1 / 24 })
    dispatch(closeOverlay('newsletter'))
  }

  render() {
    const { dispatch, screenSize } = this.props

    return (
      <div
        className={classnames(
          'newsletterOverlay',
          'overlay',
          'overlay--center'
        )}
        style={{
          width: screenSize.width,
          height: screenSize.height,
        }}
        onClick={this.closeOverlayHandler}
      >
        <div
          className="overlay-window newsletterOverlay-window"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <CloseButton iconOnly={true} onClick={this.closeOverlayHandler} />
          <div className="textBlock textBlock--center">
            <h4>For updates on the world of Kiss House</h4>
          </div>
          <Newsletter />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(
  mapStateToProps,
  null
)(NewsletterOverlay)
