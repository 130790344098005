// —————————————————
// UI
// —————————————————

// TYPES

export const RESIZE_SCREEN = 'RESIZE_SCREEN'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const HEADER_COLOUR_SET = 'HEADER_COLOUR_SET'
export const HEADER_COLOUR_TRANSITION = 'HEADER_COLOUR_TRANSITION'
export const HEADER_SET_HOVER = 'HEADER_SET_HOVER'
export const OPEN_OVERLAY = 'OPEN_OVERLAY'
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY'
export const TOGGLE_PAGE_INTRO = 'TOGGLE_PAGE_INTRO'
export const TOGGLE_PAGE_TRANSITIONING = 'TOGGLE_PAGE_TRANSITIONING'
export const UPDATE_CURSOR = 'UPDATE_CURSOR'
export const DISMISS_COOKIE = 'DISMISS_COOKIE'

// ACTIONS

export const resizeScreen = (size) => ({
	type: RESIZE_SCREEN,
	screenSize: size
})

export const toggleMenu = (bool) => ({
	type: TOGGLE_MENU,
	open: bool
})

export const setHeaderColour = (colour) => ({
	type: HEADER_COLOUR_SET,
	colour
})

export const setHeaderHover = (index) => ({
	type: HEADER_SET_HOVER,
	index
})

export const transitionHeaderColour = (colourTo, amountPixels) => ({
	type: HEADER_COLOUR_TRANSITION,
	colourTo,
	amountPixels
})

export const openOverlay = (data) => ({
	type: OPEN_OVERLAY,
	data
})

export const closeOverlay = (data) => ({
	type: CLOSE_OVERLAY,
	data
})

export const togglePageIntro = (bool) => ({
	type: TOGGLE_PAGE_INTRO,
	bool
})

export const togglePageTransition = (bool) => ({
	type: TOGGLE_PAGE_TRANSITIONING,
	bool
})

export const updateCursor = (icon, x, y) => ({
	type: UPDATE_CURSOR,
	icon: icon,
	position: icon ? {
		x: x,
		y: y
	} : null
}) 

export const dismissCookie = () => ({
	type: DISMISS_COOKIE
})