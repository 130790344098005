import React, {
	useState
} from 'react'
import classnames 	from 'classnames'
import {
	isFunction
} from 'lodash'

export default (props) => {
	const {
		onChange,
		options,
		label,
		active
	} = props

	const [open, setOpen] = useState(false)

	return <div
		className={ classnames('housesDrop', {
			'housesDrop--open': open
		}) }>
		<div
			className="housesDrop-select"
			onClick={ (e) => {
				e.preventDefault()
				e.stopPropagation()

				setOpen(!open)
			} }>
			<div>
				<span className="housesDrop-select-label">{ label }</span>
				<span>{ options[active] }</span>
			</div>
			<div className="housesDrop-select-toggle" />
		</div>
		{ open && options && options.length > 0 ? <div className="housesDrop-options">
				{ options.map((_option, _i) => {
					return <div
						key={ _i }
						className="housesDrop-options-option"
						onClick={ (e) => {
							e.preventDefault()
							e.stopPropagation()

							setOpen(false)
							
							if(onChange && isFunction(onChange)){
								onChange(_i)
							}
						} }
						>
						{ _option }
					</div>
				}) }
			</div> : null }
	</div>
}