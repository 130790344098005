import React 			from 'react'
import classnames		from 'classnames'
import { connect } 		from 'react-redux'
import {
	get
} from 'lodash'

import {
	ImageGalleryWithBreadcrumbs
} from '../image-gallery'

import breakpoints 		from '../../lib/constants/breakpoints.json'
import {
	formatTitle,
	renderHTML
} from '../../lib/_helpers'

class Split extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { 
			images,
			prefix,
			title,
			textContent,
			screenSize,
			footerContent
		} = this.props

		const isMobile = screenSize.width <= breakpoints.mobilePortrait

		const spacerEl = <div className={ classnames('sectionSpacer', {
			'sectionSpacer--half': isMobile
		}) } />

		return <div className="split">
        	<div className="split-column split-column--images">
        		{ images ? <ImageGalleryWithBreadcrumbs
        			images={ images } /> : null }
        	</div>
            <div className="split-column split-column--content">
            	{ spacerEl }
            	<div className="cont cont--half cont--half-center">
	                <div className="textBlock split-title">
	                	{ prefix ? <h6>{ renderHTML(prefix) }</h6> : null }
	                	{ title ? <h1 className="p--h3">{ formatTitle(title) }</h1> : null }
	            	</div>
	            	{ textContent || footerContent ? <div className="split-content">
	            		{ textContent ? <div className="textBlock">
	            			{ renderHTML(textContent) }
	            		</div> : null }
	            		{ footerContent ? <div className="textBlock textBlock--small">
	            			{ renderHTML(footerContent) }
	            		</div> : null }
	            	</div> : null }
	            </div>
	            { spacerEl }
            </div>
        </div>
	}
}

const mapStateToProps = (state) => {
    return {
    	screenSize: state.ui.screenSize
    }
}

export default connect(mapStateToProps)(Split)